.MyAEGDashboard {
    
    background-color: #F8F9FA;
    background-image: url($dist + '/AGraduated-color@2x.png');
    background-repeat: no-repeat;
    background-size: 654px auto;
    background-position: top left -15%;

    @screen  md+ {
        background-size: 354px auto;
        background-position: top right -45px;
        background-position-y: -135px;
    }
    @screen  md {
        background-image: none;
    }

    .select2-selection__rendered {
        font-size: 24px !important;
    }

    .MyAEGCta {
        @apply  mt-0
                bg-none;

        &-form {
            @apply  w-full;


            &--title {
                @apply  heading-jb-2;
                @apply  tracking-normal
                        leading-10
                        font-azo
                        text-white
                        mb-[20px];
            }

            form {
                > .btn[type=submit] {
                    @apply  mt-[18px];
                }
            }
        }

        &-field {
            
            label {
                @apply  min-w-max
                        text-red-bright
                        font-montserrat
                        text-24
                        font-regular
                        tracking-normal
                        leading-[49px];
            }

            select {
                // width: 450px;
                // @apply  w-9/12;
            }
        }
    }

    &-desktopSidebar {
        min-height: 417px;
        @apply  w-1/3
                sticky
                top-9
                bg-blue
                rounded-lg
                shadow
                px-5
                py-4;

        @screen md+ {
            @apply  hidden
                    pointer-events-none;
        }

        .MyAEGCta {
            &-field {
                @apply  w-full;
                &--interest {
                    
                }
            }
            &-btn {
                margin-top: 175px !important;
            }
        }
    }

    &-mobileBtn {
        @apply  hidden 
                items-center 
                justify-center
                bg-blue-bright
                p-2
                rounded-full
                mr-2;

        > img {
            @apply  w-[20px]
                    h-[20px];
        }

        @screen lg {
            @apply  flex;
        }
    }

    &-mobileSidebar {
        
        // left: -1rem;

        @apply  fixed
                pt-12
                w-3/5
                h-full
                min-h-max
                bg-blue
                text-white
                top-0
                transition-all
                -translate-x-[150%]
                px-[38px]
                z-[60]
                shadow-sidebar;

        @screen  sm+ {
            @apply  w-full;
        }

        &.active {
            @apply  translate-x-0;
        }

        &--header {
            @apply  flex 
                    justify-between;

            h3 {
                @apply  heading-jb-2;
                @apply  text-white
                        leading-10
                        tracking-normal;
            }
        }

        &--form {
            .MyAEGCta {
                &-form {
                    h2 {
                        @apply  pb-2
                                text-white;
                    }
                }

                &-field {
                    @apply  mt-1
                            flex-row
                            w-full;

                    @screen md {
                        @apply  flex-col;
                    }

                    label {
                        @apply  text-[24px]
                                font-montserrat
                                tracking-normal
                                leading-[49px];
                    }

                    &--select2 {
                        @apply  w-3/4;

                        .select2 {
                            &-container {
                                &--default {
                                    // @apply  w-full;
                                    width: 100% !important;


                                    .select2-selection {
                                        &--single {
                                            @apply  min-h-[35px]
                                                    leading-[35px]
                                                    mt-1;

                                            .select2-selection__rendered {
                                                --tw-rotate: 0deg;
                                                @apply  font-azo
                                                        text-white
                                                        text-[15px]
                                                        tracking-normal
                                                        leading-[19px]
                                                        min-h-[35px]
                                                        translate-y-[4px]
                                                        pl-0;

                                                
                                                // transform: translateY(4px);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        @screen md {
                            @apply  w-full;
                        }
                    }
                }
            }
        }

        &--overlay {
            @apply  absolute
                    transition-all
                    hidden
                    opacity-0
                    h-screen
                    w-screen
                    top-0
                    z-[-1000]
                    backdrop-blur
                    bg-[rgba(66,85,99,0.5)];

            &.active {
                @apply  fixed
                        inline-block
                        h-screen
                        w-screen
                        z-[51]
                        opacity-100;
            }
        }


    }

    &-loader {
        @apply  flex 
                items-center 
                justify-center
                mt-9;

        .lds-ring {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
        }
        
        .lds-ring div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border: 8px solid $blue;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: $blue transparent transparent transparent;
        }
        
        .lds-ring div:nth-child(1) {
            animation-delay: -0.45s;
        }
        
        .lds-ring div:nth-child(2) {
            animation-delay: -0.3s;
        }
        
        .lds-ring div:nth-child(3) {
            animation-delay: -0.15s;
        }
        
        @keyframes lds-ring {
            0% {
                transform: rotate(0deg);
            }
        
            100% {
                transform: rotate(360deg);
            }
        }
    }

    &-flash {
        @apply  absolute 
                z-20 
                top-1 
                shadow-sm 
                border-0 
                border-r-[3px] 
                border-[#1B8754] 
                py-[16px] 
                px-[17px] 
                flex 
                justify-between
                transition-all
                duration-700
                right-[-200%]
                opacity-0;

        &.active {
            @apply  right-0
                    opacity-100;
        }
    }
}

.MyAEGCta-field--select2 {
    @apply
        w-full;
}

.MyAEGCard-container {
    @apply  grid
            grid-cols-2
            auto-rows-auto
            gap-3;
}

.MyAEGCard {
    @extend .Card;

    @apply  h-full
            w-full
            shadow-myAEG
            col-span-1
            flex
            flex-col;

    .Card-body {
        @apply  pl-[35px]
                pr-[35px]
                pb-[35px];

        > :not([hidden]) ~ :not([hidden]) {
            @apply  mt-0;
        }
    }

    &-anchor {
        @apply  w-full 
                h-full 
                flex 
                flex-col
                items-start
                justify-between
                z-50;
    }

    &-intro {
        @apply  h-[352px];
    }

    &--lg {
        @apply  flex-row
                justify-between
                col-span-2;

        .MyAEGCard-anchor {
            @apply  flex-row;
        }        
    }

    .btn {
        @apply  pb-0;
    }

}

.MyAEGCard-blog,
.MyAEGCard-showcase {

    .Card {
        &-body {
            @apply  relative
                    items-start
                    h-full
                    pb-4;

            
        }

        &-image {
            @apply  max-h-[160px]
                    w-full
                    h-auto
                    aspect-h-6;

            > * {
                @apply  top-[unset];
            }
        }

        &-title {

            @apply  heading-jb-6;

            @apply  text-red
                    mt-0
                    pb-0
                    pt-0;
        }

        &-myAEGDate {
            @apply  bg-blue
                    uppercase
                    small;
                    
            @apply  text-white
                    py-1
                    pr-3
                    pl-[36px]
                    rounded-tr-lg
                    h-[37px]
                    w-max
                    left-0
                    bottom-0;
        }
    }


    &.MyAEGCard--lg {
        max-height: 330px;
        .Card {
            &-image, &-body {
                @apply  w-1/2;
            }
            &-body {
                padding-top:15px !important;
            }
            &-image {
                @apply  max-h-full;
            }

            &-myAEGDate {
                @apply  bg-white
                        text-gray-800
                        mt-1
                        pl-0;
            }
        }
    }
}

.MyAEGCard-showcase {

    &.MyAEGCard--lg {

        .Card-image {

        overflow:hidden;

        &:before {

            content: '';
            position: absolute;
            top: 0!important;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            background: rgb(0,48,82);
            background: -moz-linear-gradient(90deg, rgba(0,48,82,1) 0%, rgba(0,48,82,0) 65%);
            background: -webkit-linear-gradient(90deg, rgba(0,48,82,1) 0%, rgba(0,48,82,0) 65%);
            background: linear-gradient(90deg, rgba(0,48,82,1) 0%, rgba(0,48,82,0) 65%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#003052",endColorstr="#003052",GradientType=1);
        }
    
        &:after {
            @apply  w-[200px]
                    absolute
                    top-0
                    right-0
                    -translate-y-[14.5%]
                    -translate-x-[267%];
    
            content: url($dist + '/A-MyAEG-Showcase.svg');
            z-index:2;
            }
        }
        .Card {
            &-body {
                padding-top:38px !important;
            } 
        }
    }

    &.MyAEGCard--sm {

        .Card-image {

            overflow:hidden;
    
        &:before {

            content: '';
            position: absolute;
            top: 0!important;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            background: rgb(0,48,82);
            background: -moz-linear-gradient(90deg, rgba(0,48,82,1) 0%, rgba(0,48,82,0) 100%);
            background: -webkit-linear-gradient(90deg, rgba(0,48,82,1) 0%, rgba(0,48,82,0) 100%);
            background: linear-gradient(90deg, rgba(0,48,82,1) 0%, rgba(0,48,82,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#003052",endColorstr="#003052",GradientType=1);
        }

        &:after {
            @apply  w-[200px]
                    absolute
                    top-0
                    right-0
                    -translate-y-[32.5%]
                    -translate-x-[147%];
    
            content: url($dist + '/A-MyAEG-Showcase.svg');
            --tw-scale-x: 49%;
            --tw-scale-y: 49%;
            z-index:2;
            }
        }
    }
}

.MyAEGCard-blog {
    .MyAEGCard-anchor {
        @apply  items-start
                justify-start;
    }
    .Card {
        &-copy {
            @apply  text-14
                    line-clamp-4
                    pb-2;
        }
    }
}

.MyAEGCard--sm {
    .Card {
        &-myAEGDate {

        }
    }
}

.MyAEGCard-cta,
.MyAEGCard-ctaService,
.MyAEGCard-partner,
.MyAEGCard-ctaSolution,
.MyAEGCard-markets {

    background-image: var(--img); 
    background-size: cover;
    background-position: center center;

    @apply  min-h-[400px]
            h-full;

    &-anchor {
        @apply  flex-col;
    }

    .Card {
        &-body {
            @apply  w-full
                    h-full
                    mt-auto
                    self-end
                    bg-blue-dark
                    bg-opacity-80
                    items-start
                    pl-[35px]
                    pr-[50px]
                    pt-[45px];
                    // h-min
        }
        &-cta {
            &--subtitle {
                @apply  text-red-bright
                        font-montserrat
                        text-14
                        font-medium
                        tracking-[1.42px]
                        leading-[17px]
                        uppercase;
            }
        }

        &-title {
            @apply  text-white
                    pb-[3px];
        }

        &-copy {
            @apply  hidden;
        }
        &-body--sm {
            @apply  max-h-[255px];
        }
    }

    &.MyAEGCard--lg {
        .Card {
            &-title {
                @apply  heading-jb-4;

                @apply  text-white;
            }
            &-body {
                @apply  w-1/2
                        max-h-[unset]
                        h-full
                        mt-0;
            }

            &-copy {
                @apply  block
                        regular;

                @apply  text-white;
            }
        }
    }
    &.MyAEGCard--sm {
        .Card {
            &-title {
                @apply  heading-jb-6;
                        
                @apply  text-white;
            }
            &-copy {
                @apply  block
                        small;

                @apply  text-white;
            }
        }
    }
}


.MyAEGCard-ctaSolution {

    &.MyAEGCard--lg {
        .Card {
            &-title {
                padding-top: 7px;
            }
        }
    }
    &.MyAEGCard--sm {
        .Card {
            &-title {
                padding-top: 7px;
            }
        }
    }
}

.MyAEGCard-cta {

    .Card {
        &-body {
            margin-top:185px;
            padding-top: 24px;
        }
    }

    &.MyAEGCard--lg {
        .Card {
            &-body {
                padding-top: 47px;
                padding-bottom: 48px;
            }
            &-title {
                padding-top: 10px;
            }
        }
    }


    &.MyAEGCard--sm {
        .Card {
            &-title {

                padding-top: 7px;

                @apply  heading-jb-4;
                        
                @apply  text-white;
            }

            &-copy {
                @apply  hidden;
            }
        }
    }
}

.MyAEGCard-ctaService {
    .Card {
        &-body {
            @apply  ml-auto
                    bg-blue-dark
                    bg-opacity-100
                    pt-2
                    pb-[37px]
                    mt-[20px];

            > button.MyAEGCard-button:not([hidden]) {
                @apply  mt-auto;
            }
        }
        
        &-copy {
            @apply  block
                    text-white;
        }
    }
    .service-card-icon-mobile {
        background-color: $blue;
        padding: 10px;
    }
    &.MyAEGCard--lg {
        .Card {
            &-body {
                padding-top: 47px;
                padding-bottom: 47px;
            }
            &-title {
                padding-top: 8px;
            }
        }
    }
    &.MyAEGCard--sm {
        .Card {
            &-body {
                padding-top: 27px;
            }
        }
    }
}

.MyAEGCard-partner {

    min-height: unset;

    .Card {
        &-body {
            @apply  ml-auto
                    bg-white
                    bg-opacity-100
                    pt-2
                    pb-[37px]
                    mt-[20px];

            > button.MyAEGCard-button:not([hidden]) {
                @apply  mt-auto;
            }
        }
        
        &-title {
            @apply  block;
            color: #495057 !important;
        }
    }
    &.MyAEGCard--lg {
        .Card {
            &-body {
                padding-top: 47px;
                padding-bottom: 47px;
            }
            &-title {
                padding-top: 8px;
                padding-bottom: 20px;
            }
        }
        @screen md {
            background-size: 678px !important;
            background-position: bottom !important;
        }
        @screen sm {
            background-size: 500px; 
            background-repeat: no-repeat; 
            background-position: 0px 0px;
        }
    }
    &.MyAEGCard--sm {
        .Card {
            &-body {
                padding-top: 27px;
            }
        }
    }
}

.MyAEGCard-ctaService2 {
    .MyAEGCard-anchor {
        @apply  px-[37px]
                py-4;
    }

    .Card {
        &-body {
            @apply  px-0
                    pb-0
                    h-full
                    mt-auto
                    items-start;
        }
    }

    &.MyAEGCard--lg {
        .MyAEGCard-anchor {
            @apply  pt-3
                    pb-4
                    pr-6;
        }
        .Card {
            &-body {
                @apply  py-0
                        pl-4;
            }
        }
    }
}

.MyAEGCard-ctaSolution {
    .MyAEGCard-anchor {
        &:before {
            content: ' ';

            @apply  h-1/2
                    w-full;

            background: linear-gradient(to bottom, transparent, rgba($blue, 0.6))
        }

    }
    .Card {
        &-body {
            @apply  ml-auto
                    w-full
                    h-max
                    max-h-[unset]
                    mt-0
                    relative;

            background: linear-gradient(to bottom, rgba($blue, 0.6), $blue 40%);
        }
        

        &-copy {
            @apply  block
                    text-white
                    pb-2;
        }

    }

    &.MyAEGCard--lg {
        .MyAEGCard-anchor {
            &:before {
                @apply  h-full
                        w-1/2;

                background: linear-gradient(to right, transparent, rgba($blue, 0.8));
            }
            
        }
        .Card {
            &-body {
                @apply  w-1/2
                        h-full;


                background: linear-gradient(to right, rgba($blue, 0.8), $blue 20%);
            }
        }
    }
}

.MyAEGCard-showcase {
    .Card {
        &-body {
            @apply  h-full
                    ml-0
                    order-1
                    pb-[37px];
        }


        &-subtitle {
            @apply  text-[#2E3092]
                    font-montserrat
                    text-12
                    font-medium
                    tracking-[0.86px]
                    leading-[15px]
                    uppercase
                    pb-1;
        }

        &-title {
            @apply  text-gray-700
                    font-montserrat
                    text-21
                    tracking-normal
                    leading-[25px]
                    pb-1;
        }

        &-copy {
            @apply  small
                    mt-0;
        }
    }

    &.MyAEGCard--lg {
        .Card {
            @apply  order-1;

            &-image {
                @apply  order-2;
            }
        }
    }

}

.MyAEGCard-stat {
    @apply  bg-blue
            relative;

    &:after {
        @apply  w-[200px]
                h-full
                absolute
                top-0
                right-0
                -translate-y-[30%]
                -translate-x-[147%];

        content: url($dist + '/A-MyAEG-Stat.svg');
    }

    .Card {
        &-body {
            @apply  w-3/4
                    h-full
                    mt-auto
                    items-start
                    justify-end
                    flex-col
                    min-h-[390px]
                    pb-[55px];
        }

        &-subtitle--stat {
            @apply  text-[#00A3E0]
                    font-montserrat
                    text-14
                    font-medium
                    tracking-[1.42px]
                    leading-[17px]
                    uppercase;
        }

        &-myAEGStat {
            @apply  text-red-bright
                    font-montserrat
                    text-[130px]
                    font-thin
                    tracking-[-2.17px]
                    leading-[128px];
        }

        &-myAEGStatCopy {
            @apply  heading-jb-4;

            @apply  text-white;
        }
    }
}

.MyAEGCard-download {
    @apply  relative
            min-h-[227px];

    .btn {
        @apply  pb-1;
    }

    .Card {
        &-downloadImage {
            margin-left: 35px;
            margin-top: 30px;
        }
        &-body {
            @apply  w-full
                    h-full
                    mt-auto
                    items-start
                    justify-end
                    flex-col
                    pb-[45px]
                    pt-[30px];
        }
        &-title {
            max-width: 350px;
            margin-bottom: 15px;
            line-height: 34px;
        }
        &-subtitle {
            @apply  text-red
                    font-montserrat
                    text-14
                    font-medium
                    tracking-[1.42px]
                    leading-[17px]
                    uppercase
                    pb-1;
        }
    }
    &.MyAEGCard--lg {

        &:after {
        @apply  w-full
                h-full
                absolute
                top-0
                right-0
                -translate-x-[-52%]
                -translate-y-[59%];

        content: url($dist + '/A-MyAEG-Download.svg');
        --tw-scale-x: 100%;
        --tw-scale-y: 100%;
        }

        .Card {
            &-downloadImage {
                max-width: 164px;
            }
        }
    }

    &.MyAEGCard--sm {

        &:after {
        @apply  w-full
                h-full
                absolute
                top-0
                right-0
                -translate-x-[-22%]
                -translate-y-[19%];

        content: url($dist + '/A-MyAEG-Download.svg');
        --tw-scale-x: 130%;
        --tw-scale-y: 130%;
        }

        .Card {
            &-downloadImage {
                max-height: 147px;
            }
        }
    }
}

.MyAEGCard-generalLead {
    @apply  bg-blue;

    .Card {
        &-body {
            @apply  items-start;

            > .btn {
                @apply  mt-[24px];
            }
        }

        &-subtitle {
            @apply  text-red-bright
                    font-montserrat
                    text-14
                    font-medium
                    tracking-[1.42px]
                    leading-[17px]
                    uppercase
                    pb-1;
        }

        &-title {
            @apply  text-white
                    font-montserrat
                    text-[30px]
                    font-light
                    tracking-normal
                    leading-10
                    pr-6
                    pb-3;
        }
    }

    &.MyAEGCard--lg {
        .MyAEGCard-anchor {
            @apply  flex
                    flex-row
                    justify-between;
        }

        .Card {
            &-body {
                @apply  w-3/5
                        h-full
                        pl-[36px]
                        py-[45px];


                > .btn {
                    @apply  mt-6;
                }
            }

            &-title {
                @apply  text-[50px]
                        font-thin
                        leading-[57px]
                        pr-0
                        pb-5;
            }
        }
    }
    &.MyAEGCard--sm {
        .Card {
            &-icon {
                @apply  w-[280px]
                        mt-[-10px]
                        ml-[-60px];
            }
        }
    }
}

.MyAEGCard-markets {
    .Card {
        &-body {
            @apply  self-end
                    bg-opacity-100
                    ml-auto
                    pt-[45px]
                    pb-[48px]
                    mt-[133px];

            > .btn {
                @apply  mt-[42px];
            }
        }

        &-copy {
            @apply  block
                    text-white
                    h-max;
        }

        &-title {
            @apply  mb-[4px]
                    pb-[3px];
        }
    }
    &.MyAEGCard--sm {
        .Card {
            &-body {
                padding-top: 27px;
                padding-bottom: 38px;
            }
        }
    }
}

.Card-SecondTitle {
        margin-top: -3.125rem;
    }


@screen lg {
    .MyAEGCard {
        &-container {
            @apply  grid-cols-2
                    gap-2;
        }
    }

    .MyAEGCard {
        @apply  col-span-1;

        &.MyAEGCard--lg {
            @apply  col-span-2;
        }
    }
}


@screen md {

    .MyAEGDashboard {
        .container {
            @apply  max-w-[90%];
        }
    }

    .MyAEGCard {

        &-container {
            @apply  grid-cols-1
                    gap-x-0
                    gap-y-2;
        }

        &.MyAEGCard--lg {
            @apply  flex-col
                    col-span-1
                    max-h-[unset];
    
            .MyAEGCard-anchor {
                @apply  flex-col;
            }        
        }
    }

    .MyAEGCard {
        @apply  h-full
                w-full
                min-w-min
                shadow-myAEG
                col-span-1
                flex
                flex-col;

        &-anchor {
            @apply  w-full 
                    h-full 
                    flex 
                    flex-col
                    items-start
                    justify-between;
        }

        &.MyAEGCard {
            &-blog, &-showcase {
                .Card {
                    &-image, &-body {
                        @apply  w-full;
                    }

                    &-image {
                        @apply  max-h-[160px];
                    }

                    &-myAEGDate {



                    }
                }
            }

            &-cta,
            &-ctaService,
            &-partner,
            &-ctaSolution,
            &-markets {
                .Card {
                    &-body {
                        @apply  w-full
                                max-h-[55%]
                                h-full
                                mt-auto;
                                // h-min
                    }
                    &-copy {
                        @apply  hidden;
                    }
                }
                        
            }

            &-partner {
                height: 425px;
                .Card {
                    &-body {
                        @apply  pt-2;
                    }
                }
            }

            &-ctaService2 {
                .MyAEGCard-anchor {
                    @apply  px-[37px]
                            py-4;
                }

                .Card {
                    &-body {
                        @apply  px-0;
                    }
                }
            }

            &-ctaSolution {
                .MyAEGCard-anchor {
                    &:before {
                        @apply  h-1/2
                                w-full;

                        background: linear-gradient(to bottom, transparent, rgba($blue, 0.6))
                    }

                }
                .Card {
                    &-body {
                        @apply  w-full
                                h-full;

                        background: linear-gradient(to bottom, rgba($blue, 0.6), $blue 40%);
                    }
                    
                }
            }

            &-showcase {
                .Card {
                    @apply   order-2;

                    &-image {
                        @apply  order-1;
                    }   
                }
            }

            &-generalLead {

                .MyAEGCard-anchor {
                    > img {
                        @apply  order-1
                                w-[78px]
                                h-[78px]
                                max-w-[unset];
                    }
                }

                .Card-icon {
                        order: 1;
                        height: 155px !important;
                        width: 155px !important;
                        max-width: unset;
                        top: 0px !important;
                        left: 0px !important;
                        margin-left: 0px;
                        margin-top: 0px;
                    }
                .Card {
                    &-body {
                        @apply  w-full
                                h-full
                                pl-[35px]
                                pr-[35px]
                                py-2
                                order-2;
            
                        > .btn {
                            @apply  mt-[24px];
                        }
                    }
            
            
                    &-title {
                        @apply  text-white
                                font-montserrat
                                text-[30px]
                                font-light
                                tracking-normal
                                leading-10
                                pr-6
                                pb-1;
                    }
                }
            }

            .Card {
                &-body {

                }
                &-image, > img {
                    @apply  max-h-[160px]
                            pb-[160px];
                }
            }
        }
        &.MyAEGCard--lg {
                    .Card-myAEGDate {

                        @apply  bg-blue
                                pl-[36px]
                                text-white
                                small;
                                
                        margin-top: -55px !important;
                        margin-left: -35px;
                        margin-bottom: 20px;

                    }
                    .Card-copy {
                        @apply mb-2;
                    }
        }
    }
    .Card-SecondTitle {
        margin-top: -0.75rem;
    }
}

.MyAEGContactForm {
    @extend  .ContactForm;

    > .fui-i {
        @apply  w-full;

        .ContactForm-form {
            .fui-field:not(.fui-type-hidden-field) {
                @apply  mb-0;
            }

            .fui-form-container .fui-btn-container {
                @apply  w-max;
            }
        }
    }
}


.MyAEGCta-field--select2 .select2-container--default .select2-selection--single .select2-selection__arrow b {
    transform: none !important;
}
.MyAEGCta-field--select2 .select2-container--default .select2-selection--single .select2-selection__rendered {
    text-align: left !important;
    // width: 165px !important;

    &:first-letter {
        @apply  capitalize;
    }
}

.notYouMobile {
    display: none;
    position: absolute;
    left: 80px;
    top: 57px;

    @screen  md {
        display: block;
    }
}

.MyAEGName {
    @screen  md {
        position: relative;
        top: -15px;
    }
}