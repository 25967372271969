.PartnersFilter{
    @apply
        rounded-full
        relative
        z-50
        border-[15px]
        border-white
        bg-gray-100
        -translate-y-1/2
        flex;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.3), 0px 3px 7px rgba(0, 0, 0, 0.2);
    
    &-item{
        @apply
            flex-1
            flex
            relative;
        
        &:nth-child(n+2)::before{
            content: '';
            @apply
                border-l
                border-gray
                my-sm;
        }
    }

    input{
        @apply 
            flex-1 
            bg-transparent 
            rounded-full
            rounded-l-none
            h-5
            pl-2;
        padding-right: calc(25% + 10px);
    }

    select{
        @apply
            w-full
            appearance-none
            pl-2
            relative
            z-10;

        background: transparent url($dist + '/arrow-down.svg') right 15px center no-repeat;
    }

    button{
        @apply
            absolute 
            right-sm 
            top-sm 
            h-4 
            min-w-[25%];
    }

    &-icon{
        @apply 
            w-6 
            h-5 
            flex-grow-0 
            block
            relative;

        svg{
            @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
            width: 28px;
            transition: none !important;
        }
        .htmx-indicator{
            transition: none !important;
        }
    }


    //Stacking layout
    @screen md{
        @apply
            flex-col
            bg-white
            rounded-xl
            pb-5;
        box-shadow:  0px 3px 7px rgba(0, 0, 0, 0.2);

        .PartnersFilter-icon{
            @apply hidden;
        }

        &-item:not(:last-child){
            @apply
                border-none
                bg-gray-100
                rounded-full
                mb-1;

            box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.3);

            &:nth-child(n+2)::before{ @apply border-none }
        }

        select{
            @apply h-5;
        }

        button{
            @apply 
                h-5 
                w-full;

            font-size: 18px;

        }


    }

}
.htmx-request .PartnersFilter-icon--static{
    @apply hidden;
}

.partnerTitle {
    @apply text-blue;
}

.PartnersList{
    margin-bottom: -300px;

    .Card{
        @apply
            flex
            flex-col;
    }

    .Card-image{
        --tw-aspect-h: 6;
    }

    .Card-body{
        @apply
            flex
            flex-col
            h-full
            justify-between;
    }

    &-partnerIntro{
        @apply
            flex-1;
    }
}

.PartnerStatBlocks {
    @apply  relative;

    &:after {
        @apply  absolute
                block
                w-full
                h-[44px]
                bottom-0
                bg-[#678498];

        content: '';
    }
}