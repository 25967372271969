.Contact {
    &-copy {
        @apply  pr-10
                mb-4;

        p {
            @apply  mb-3;

            &:last-child {
                @apply  mb-0;
            }
        }

        @screen lg {
            @apply  pr-0;
        }
    }

    &-address {
        p, a {
            @apply  font-montserrat 
                    text-26 
                    font-light 
                    text-red 
                    leading-9 
                    pr-20;
        }

        @screen lg {
            p, a {
                @apply  text-21
                        pr-0;
            }
        }
    }
}

.ContactForm {


    .fui-i {
        @apply  w-4/5
                ml-auto;

        @screen lg {
            @apply  mr-auto
                    ml-0
                    mt-7
                    w-full;
        }
    }

    &-form {
        .fui-form-container {
        }

        .fui-field-container {
            .fui-label {
                @apply  mb-1;
            }

            .fui-input-container {
                input[type=email], 
                input[type=text],
                input[type=tel],
                select,
                textarea,
                .fui-input {
                    @apply  rounded
                            w-full
                            pt-1 pr-1 pb-1 pl-1
                            text-14
                            leading-6
                            text-gray-700
                            font-azo;
                    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.3);
                }

                input[type=tel] {
                    @apply  pl-[52px];
                }

                select {
                    @apply  appearance-none;

                    background: url($dist + '/down-arrow--select.svg') no-repeat;
                    background-position: right $spacing-2 center;

                    
                }
            }

            .fui-type-multi-line-text {
                .fui-input-container {
                    > label {
                        > .fui-required {
                            @apply  hidden;
                        }
                    }
                }
            }
        }

        .fui-form-container {
            .fui-btn-container {
                @apply  w-full
                        mx-0;

                .fui-submit {
                    @extend  .btn;
                    @apply  w-full
                            border-0
                            mx-auto;
                }
            }
        }
    }
}

.ContactInfoWindow {
    &-title {
        @apply  text-red
                text-[24px]
                font-montserrat
                leading-[3.125rem];
    }
}

/* #map .gm-control-active>img {
    display: none;
}

.gm-control-active>img:nth-child(1) */

#map {
    .gm-control {
        &-active {
            > img {
                @apply  hidden;

                &:nth-child(1) {
                    display: block;
                }
            }
        }
    }
}