.StatBox{
    @apply
        bg-white
        rounded-xl
        rounded-bl-none
        px-3
        py-4
        box-border
        w-2/12
        shadow-statBox;

    &-data{
        display: grid;
    }

    &-icon {
        img {
            @apply  max-h-[55px];
        }
    }

    &-number{
        @apply
            text-60
            font-azo
            font-light
    }

    @screen xl{
        @apply w-3/12 px-2 py-3;
    }

    @screen md{
        @apply  text-center
                w-1/2
                mx-auto
                px-3
                mr-3;

        &-data{
            @apply flex flex-col;
        }
        &-icon {
            img {
                @apply  m-auto;
            }
        }
    }
}