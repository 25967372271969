.Sitemap {
    @extend  .NewsDetail;

    background-position: bottom 50% right;

    .container > ul {
        li {
            h5, h6 {
                @apply  h-unset
                        font-montserrat
                        text-20
                        leading-[27px]
                        font-medium
                        text-gray-800;
            }
            
            h5 {
                @apply  mb-[16px];
            }

            // Subnav links
            > ul {
                @apply  ml-[35px]
                        mb-2;

                li {

                    > h6 {
                        a:before {
                            @apply  text-red;
                        }

                        + ul {
                            @apply  mt-1;

                            li {
                                @apply mb-1;

                                a {
                                    @apply  font-azo
                                            font-light
                                            text-16
                                            tracking-[0];
                                }
                            }
                        }
                    }

                    a {
                        @apply  flex
                                flex-row; 
        
                        &:before {
                            @apply  pr-[12px]
                                    text-blue-dark;

                            content: '\2013';
                        }

                        &:hover {
                            @apply  text-red;
                        }
                    }
                }
            }
        }
    }
}