.ServiceSummary {
    @screen md{ 
        margin-left: 0px !important;
    }
}
.ServiceIntro {
    @screen md+{
        @apply
            mt-0;
        
        &.shadow-top {
            &::before, &::after {
                @apply  hidden;
            }
        }
    }
}

.Service-icon {
    @screen md+ {
        width: 88px;
    }
}