.HomeHero{

    filter:drop-shadow(0 0 5px black);

    .Hero-mask{
        @apply bg-no-repeat;
        background-position: 38vw 0%;

        @screen md{
            @apply  bg-center 
                    bg-cover 
                    bg-local;
            //background-size: 100% 100%;
        }
    }

    @screen md {
        min-height: 350px;
    }

    &-title {
        line-height: 85px;

        @screen sm {
            line-height:40px;
        }
    }

}




#slider::after{
    @screen md{
        content: 'flickity'
    }
}





.HomeService{ 
    @apply
        absolute
        rounded-lg
        shadow-md;

        
    &-wrap{
        --modifier: 1;
        --gap: 30px;
        --1-y-offset: calc( -200px * var(--modifier) );
        --1-height: calc(280px * var(--modifier));
        --2-x-offset: #{100%*1/12};
        --2-y-offset: calc( var(--1-y-offset) + var(--1-height) - var(--gap) );
        --2-width: calc( 50% - var(--gap));
        --2-height: calc( 450px * var(--modifier) );
        --3-width: #{100%*5/12};
        --3-height: calc( var(--2-height) - var(--gap)*2 );
        --4-height: calc( 340px * var(--modifier) );
        --4-y-offset: calc( var(--2-y-offset) + var(--2-height) + var(--gap) );
        --5-width: calc( 50% - var(--gap) );
        --5-height: calc( 430px * var(--modifier) );
        --overall-height: calc( var(--1-height) + var(--2-height) + var(--5-height) + var(--1-y-offset) );//needed since all the children are absolute
        
        @screen xl{
            --modifier: #{$lg / $xl};
        }
        // 768px - 1024 (container is 768px)
        @screen lg{
            --modifier: #{$md / $xl}; // 768/1440
        }
        
        @apply relative my-10;
        height: var(--overall-height);
        
        .HomeService{
            &:nth-child(1){
                top: var(--1-y-offset);
                right: calc(100%*1/12);
                @include size(100%*4/12, var(--1-height));
            }
            &:nth-child(2){
                top: var(--2-y-offset);
                left: var(--2-x-offset);
                @include size(var(--2-width), var(--2-height));
            }
            &:nth-child(3){
                top: calc( var(--1-y-offset) + var(--1-height) + var(--gap) );
                @include size(var(--3-width), var(--3-height));
                @apply right-0;
            }
            &:nth-child(4){
                @apply left-0 w-5/12;
                top: var(--4-y-offset);
                height: var(--4-height);

            }
            &:nth-child(5){
                @include size( var(--5-width), var(--5-height) );
                top: var(--4-y-offset);
                right: #{ 100% * 1 / 12 };
            }
            &:nth-child(6){
                @apply bottom-0 right-0 hidden;
            }
        }
    }

    img{
        @apply  rounded-lg 
                absolute 
                inset-0 
                object-cover 
                w-full 
                h-full
                shadow-sm;
    }

    $buttonHeight: 38px;
    //Copy
    &-copy{
        @apply h-[#{$buttonHeight}]
            absolute
            bottom-2
            left-2
            rounded-lg
            px-2
            bg-white
            transition-all
            duration-700
            overflow-hidden;
        max-width: 435px;
        max-height: calc(100% - #{$spacing-4});
        width: calc( var(--size-width) + #{$spacing-4} + 13px);

        &.is-open{
            @apply bg-white 
                   rounded 
                   pt-3 
                   pb-2 
            overflow-hidden;
            width: calc(100% - #{$spacing-4});
            height: calc( var(--size-height) + #{$buttonHeight});

                @screen xl {
                    @apply pt-1;
                }

            .HomeService-heading{
                @apply text-[24px] transition-all duration-700;
            }

            .HomeService-body p{
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;

                @screen xl {
                    -webkit-line-clamp: 2;
                }

            }
        }
    }

    &-heading{
        @apply 
            text-16
            leading-none
            inline-flex
            justify-start
            items-center
            cursor-pointer
            whitespace-nowrap
            transition-all 
            duration-700
            font-semibold
            font-montserrat
            h-[#{$buttonHeight}];

        color: #424b5a;
        
    }

    @screen xl{
        &-body{ @apply text-14;}
    }

    // Carousel/Flickity Version
    @screen xl{
        
        
        
    }
    &-carousel{
        margin-top: $spacing-5;
        scroll-snap-type: x mandatory;
        padding: 0 5vw;
        gap: 30px;
        overflow: scroll;

        .HomeService{
            scroll-margin: 10px;
            scroll-snap-align: center;
            scroll-snap-stop: normal;
            width: 90vw !important;
            height: 400px !important;
            position:  relative !important;
            right: auto !important;
            top: auto !important;
            flex-shrink: 0;
        }
    }
    
    
}


.HomeServicesTitle {
    @screen lg {
        text-align: center;
        font-size: 38px;
        line-height: 50px;
    }
}

.HomeStats{
    @apply py-1 mt-3 relative z-10;
    background: url($dist + '/city-skyline.jpg') center center / cover no-repeat;
    
    @screen retina{
        background-image: url($dist + '/city-skyline@2x.jpg')
    }

    @screen md{
        >.container{
            @apply block w-full max-w-none;
        }

        >.container:after{
            content: 'flickity';
            display: none;
        }
    }
}

.hide
    {
        display:none !important;
    }