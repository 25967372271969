.ListingHero {
    &-header {

        &--blog {
            background-position: top -100% right;

            @screen xl+ {
                background-position: right 30% top -100% !important;
            }

            @screen uw {
                background-position: right 35% top -100% !important;
            }

            @screen uw+ {
                background-position: right 40% top -100% !important;
            }
        }
    }
}

.Listings {
    &-blog {
        @apply  relative
                -top-4;
    }
}

.ListingSearch {
    @apply  w-full
            bg-white 
            shadow 
            rounded-full 
            mx-auto;

    @screen md {
        @apply  rounded-lg;
    }

    &-form {
        @apply  rounded-full
                flex
                font-azo
                text-16
                p-1 
                bg-white;

        @screen md {
            @apply  flex-col
                    rounded-lg
                    bg-white
                    shadow-none;
        }
    }

    &-sidebarTitle {
        @apply  text-22
                text-blue
                font-montserrat
                leading-[27px]
                pb-[15px]
                border-0
                border-b;

        border-color: rgba(0,48,82,0.2);
    }

    &-checkboxes {
        input[type=checkbox]:checked + span {
            @apply  text-[#2E3092];
        }
    }

    &-textInput {
        @apply  w-full 
                pl-3 
                rounded-tl-full
                rounded-bl-full
                placeholder-gray-800
                text-gray-800
                font-azo
                text-16
                font-regular
                leading-[25px]
                bg-white
                bg-no-repeat
                ml-2
                pl-4;

        letter-spacing: 0;

        background-image: url($dist + '/search.svg');
        background-position: left 3px center;

        &:focus {
            @apply  outline-none;
            // border: 1px solid $blue-dark;
            -webkit-text-fill-color: $blue-dark;
            
            // -webkit-box-shadow: 0 0 0px 1000px #000 inset;
            
            // outline: 1px solid $gray-200;
            // outline-offset: 10px;
        }

        @screen md {
            @apply  w-full
                    rounded-lg
                    bg-white
                    py-2
                    pl-4
                    shadow
                    shadow-inner;

            background-position: left 10px center;

            &:focus {
                @apply  outline-none;
                /* outline: 1px solid $gray-200;
                outline-offset: 0px; */
            }
        }
    }

    &-categories {
        @apply  w-3/12 
                ml-auto 
                bg-gray-200 
                bg-no-repeat
                appearance-none
                px-2
                mr-2
                
                border-l 
                border-r 
                border-t-0 
                border-b-0 
                border-gray-200 ;

        background-image: url($dist + '/arrow-down.svg');
        background-position: right 20px center;

        &:focus {
            outline: 1px solid $gray-200;
        }

        @screen md {
            @apply  w-full
                    border-0
                    py-2
                    bg-gray-100
                    rounded-lg
                    my-1;

            &:focus {
                outline: 1px solid $gray-100;
            }
        }
    }

    &-submit {
        @apply  w-2/12;

        @screen md {
            @apply  w-full;
        }
    }

    
}

.Loader {
    @apply  items-center
            justify-center
            py-7
            mx-auto
            hidden;

    &.htmx-request {
        @apply  flex;
    }
    
    .lds-ellipsis {
        @apply  relative 
                block
                mx-auto;
                
        width: 80px;
        height: 80px;
    }
    .lds-ellipsis div {
        @apply  bg-blue-dark;
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }

}

.ListingResults {
    @apply  grid
            grid-cols-3
            row-auto
            gap-1;

    @screen md {
        @apply  grid-cols-1;
    }
}

#loadmore.ListingResults {
    @apply  mt-1;
}

.FooterPressRelease {
    @apply  flex;
    // used to have px-5;

    margin-top: calc(-9.375rem - 15px);
    

    &-text {
        @apply  text-white
                w-1/2
                flex
                items-center
                justify-center;

        p {
            &:first-of-type {
                @apply  text-20
                        font-normal
                        font-montserrat
                        leading-7
                        mb-1
                        text-white;
            }

            @apply  text-16
                    leading-6
                    font-azo
                    text-white;


            + a.btn {
                @apply  mt-4;
            }
        }
    }

    @screen md {
        &-text {
            @apply  w-full;
        }
    }
}

.NrpOverviewPressRelease {
    &-container {
        @apply  px-9 
                relative 
                z-30; 

        background: $blue url($dist + '/A-graduated-1.png') 100% -50% no-repeat;        

        @screen md {
            background: $blue;
        }
    }

    &-text {
        @apply  w-full
                flex
                items-center
                justify-center;

        p {
            @apply  text-white;
            
            &:first-of-type {
                @apply  text-20
                        font-normal
                        font-montserrat
                        leading-7
                        mb-1;
            }

            @apply  text-16
                    leading-6
                    font-azo;


            + a.btn {
                @apply  mt-4;
            }
        }

        @screen lg {
            @apply  py-4;
        }
    }
}