$color_1: #003052;
$color_2: #DD3545;
$color_3: #495057;

$font-family_1: "azo-sans-web",sans-serif;
$font-family_2: 'Montserrat', sans-serif;
$background-color_1: white;

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * azo-sans-web:
 *   - http://typekit.com/eulas/000000000000000000013f4e
 *   - http://typekit.com/eulas/000000000000000000013f4f
 *   - http://typekit.com/eulas/000000000000000000013f4b
 *   - http://typekit.com/eulas/00000000000000007735a2d2
 *   - http://typekit.com/eulas/00000000000000007735a2df
 *   - http://typekit.com/eulas/000000000000000000013f8f
 *   - http://typekit.com/eulas/000000000000000000014a32
 *
 * © 2009-2023 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-11-23 14:29:16 UTC"}*/
@import "https://p.typekit.net/p.css?s=1&k=osd3zev&ht=tk&f=18438.18439.18440.18441.18443.18445.22801&a=574440&app=typekit&e=css";
@font-face {
	font-family: "azo-sans-web";
	src: url("https://use.typekit.net/af/9aa4fe/000000000000000000013f4e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/9aa4fe/000000000000000000013f4e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/9aa4fe/000000000000000000013f4e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}
@font-face {
	font-family: "azo-sans-web";
	src: url("https://use.typekit.net/af/90ca1f/000000000000000000013f4f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/90ca1f/000000000000000000013f4f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/90ca1f/000000000000000000013f4f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 700;
	font-stretch: normal;
}
@font-face {
	font-family: "azo-sans-web";
	src: url("https://use.typekit.net/af/b714ca/000000000000000000013f4b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/b714ca/000000000000000000013f4b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/b714ca/000000000000000000013f4b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 400;
	font-stretch: normal;
}
@font-face {
	font-family: "azo-sans-web";
	src: url("https://use.typekit.net/af/2759ad/00000000000000007735a2d2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/2759ad/00000000000000007735a2d2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/2759ad/00000000000000007735a2d2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 300;
	font-stretch: normal;
}
@font-face {
	font-family: "azo-sans-web";
	src: url("https://use.typekit.net/af/0240fd/00000000000000007735a2df/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/0240fd/00000000000000007735a2df/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/0240fd/00000000000000007735a2df/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 500;
	font-stretch: normal;
}
@font-face {
	font-family: "azo-sans-web";
	src: url("https://use.typekit.net/af/9dc3b1/000000000000000000013f8f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/9dc3b1/000000000000000000013f8f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/9dc3b1/000000000000000000013f8f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}
@font-face {
	font-family: "azo-sans-web";
	src: url("https://use.typekit.net/af/b4a4b5/000000000000000000014a32/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("woff2"),url("https://use.typekit.net/af/b4a4b5/000000000000000000014a32/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("woff"),url("https://use.typekit.net/af/b4a4b5/000000000000000000014a32/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 200;
	font-stretch: normal;
}
@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap";
.tk-azo-sans-web {
	font-family: $font-family_1;
}
.cards {
	grid-row: auto;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 24px;
	width: 68.75VW;
	margin: 0 auto;
	grid-row-gap: 50px;
	.accordion-button.card__btn.collapsed {
		display: none;
	}
	ul {
		padding-left: 0;
		li {
			list-style: none;
		}
	}
	.icon-linkedin {
		&::before {
			content: url("../images/linkedin.png");
		}
	}
	.accordion-body {
		margin-top: 20px;
		p {
			display: none;
		}
	}
}
.card {
	background-color: $background-color_1;
	border-top-left-radius: 14px;
	border-top-right-radius: 14px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border:unset;
	&:hover {
		.card__image {
			filter: contrast(100%);
		}
	}
}
.card__content {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	padding: 20px 20px 0px 25px;
	border-radius: 12px;
	background: #ffffff;
	position: relative;
	top: -15px;
	&::before {
		content: url('../images/arrow.png');
		position: absolute;
		top: -2rem;
		left: 13%;
		--tw-translate-y: -100%;
		--tw-translate-x: -50%;
		transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
		line-height: 0;
	}
}
.card__image {
	background-repeat: no-repeat;
	background-size: cover;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	overflow: hidden;
	position: relative;
	&::before {
		content: "";
		display: block;
		padding-top: 292px;
	}
}
.card__title {
	color: $color_1;
	font-size: 1.5277777777777777VW;
	font-weight: 400;
	letter-spacing: normal;
	text-transform: initial;
	font-family: $font-family_2;
	line-height: 1.9444444444444444VW;
}
.card__subtitle {
	color: $color_2;
	font-size: 0.7638888888888888VW;
	font-weight: 400;
	letter-spacing: normal;
	text-transform: uppercase;
	font-family: $font-family_1;
	line-height: 1.9444444444444444VW;
	margin-bottom: 20px;
}
.card-top-section {
	display: flex;
	justify-content: space-between;
}
.card__text {
	flex: 1 1 auto;
	font-size: 14px;
	margin-bottom: 15px;
	font-family: $font-family_1;
	line-height: 1.25VW;
	font-weight: 300;
}
.card-image {
	.img-responsive {
		width: 100%;
		height: 20.34722222222222VW;
		object-fit: cover;
		object-position: 0 0px;
		max-width: 100%;
	}
}
.card__btn {
	display: none;
}
#meet-the-team-section {
	hr {
		border-top: 1px solid #B8C5CF;
		opacity: unset;
		margin: 0;
		margin-bottom: 13px;
	}
	h4 {
		font-family: $font-family_2;
		font-weight: 300;
		line-height: 24px;
		font-size: 3.0555555555555554VW;
		text-align: center;
		border-bottom: 1px solid #dfe2e6;
		padding-bottom: 37px;
		margin-bottom: 41px;
	}
}
#loadMore {
   font-family: "azo-sans-web",sans-serif;
    font-size: 14px;
    line-height: 28px;
    font-weight: 500;
    color: #ffffff;
    background: #C20000;
    border: unset;
    width: 100%;
    border-radius: 29px;
    padding: 5px 0px;
    text-transform: capitalize;
    margin: 22px 0px 53px 0px;
    display: block;
    text-align: center;
    text-decoration: unset;
    display: none;
}
.noContent {
  color: #000 !important;
  background-color: transparent !important;
  pointer-events: none;
  display: none;
}
.team {
    .Hero {
        min-height: 541px;
    }
    #intro-head-container {
        #intro-head-image {
            img {
                width: 481px;
                height: 326px;
            }
        }
    }
}
#intro-head {
    margin-left: 125px;
    p {
        width: 506px;
        max-width: 100%;
        color: $color_3;
    }
}
div#intro-head-image {
    padding-right: 0px;
    padding-left: 48px;
}
section#meet-the-team-section {
    .card__content {
        &:before {
            top: 0.6rem;
        }
        .card__text {
            p {
                &:nth-child(1) {
                    margin-bottom: 20px;
                }
                color: $color_3;
                line-height: 18px;
            }
        }
    }
    margin-bottom: 90px;
}
.cards {
    grid-row-gap: 50px;
}
.footer-image {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.team {
    .Footer {
        padding-top: 70px;
        padding-bottom: 35px;
        background-image: unset !important;
        background-color: #003052;
        .MyAEGCta {
            margin-top: 0;
            margin-bottom: 20px;
            background-image: unset !important;
        }
        .MyAEGCta-formContainer {
            padding-bottom: 0;
            padding-top: 40px;
            width: 100%;
            box-shadow: unset !important;
            background: transparent;
            z-index:1;
        }
    }
    .FooterNav {
        padding-top: 0px;
        margin-top: 0px !important;
        position: relative;
        z-index: 2;
    }
    .Hero--overlay--partner::before{
	    -webkit-clip-path: polygon(0 0,60% 0,35% 70%,0 100%);
	    clip-path: polygon(0 0,60% 0,35% 70%,0 100%);
    }
}
.accordions_title{
	display:none;
}
@media (max-width: 767px) {
	.card__btn {
		display: block;
		img {
			float: right;
			height: auto;
		}
	}
	.accordions_content{
		display: none;
	}
	.card__text {
		display: none;
	}
	.cards {
		.accordion-button.card__btn.collapsed {
			display: block;
		}
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-row-gap: 22px;
		width: 100%;
		.card__title {
			font-size: 22px;
			line-height: 28px;
		}
		.card__subtitle {
			font-size: 11px;
			line-height: 28px;
		}
		.accordion-body {
			p {
				color: $color_3;
				flex: 1 1 auto;
				font-size: 14px;
				margin-bottom: 15px;
				line-height: 18px;
				font-weight: 300;
				font-family: $font-family_1;
				display: block;
			}
			margin-top: 25px;
		}
		.accordion-button.card__btn {
			font-family: $font-family_1;
			font-size: 14px;
			line-height: 18px;
			font-weight: 500;
			color: $color_2;
		}
		.accordion-button {
			&:after {
				background-image: url('../images/accordion-before-arrow.png');
				background-size: cover;
				position: absolute;
				right: 0;
				width: 12px;
				height: 12px;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
		.additional-info-accordion {
			.accordion-button[aria-expanded="true"] {
				&:after {
					background-image: url('../images/accordion-after-arrow.png');
				}
			}
		}
	}
	.card-image {
		.img-responsive {
			height: auto;
		}
	}
	#meet-the-team-section {
		hr {
			margin-bottom: 16px;
		}
		h4 {
			line-height: 24px;
			font-size: 30px;
		}
	}
	#loadMore {
   		display: block;
	}
	.noContent {
    	display: none;
	}
	.team {
		#intro-head-container {
			display: unset;
			#intro-head {
				margin-left: 0 !important;
				padding: 30px;
			}
			div#intro-head-image {
				padding-left: 17px;
				padding-right: 17px;
				margin-top: 30px;
				margin-bottom: 50px;
			}
			#intro-head-image {
				img {
					height: 195px !important;
					width: 100% !important;
				}
			}
		}
		#meet-the-team-section {
			max-width: 100%;
			padding: 0px 17px;
			h4 {
				margin-bottom: 40px;
				padding-bottom: 30px;
			}
		}
		.Footer {
			padding-top: 0px !important;
			.MyAEGCta-title {
				margin-top: -10px;
				margin-left: 17px;
				margin-right: 17px;
			}
		}
		.Hero {
			min-height: 400px;
			background-image: url(../images/mobile_banner.png) !important;
			background-size: 100%;
			background-position: inherit;
		}
		.Hero--overlay-container {
			h1.full-width {
				padding-bottom: 50px !important;
				padding-top: 50px !important;
			}
		}
		.Hero--overlay--partner::before{
		display:none;
		}
	}
.accordions_title{
	display:block;
	font-family: "azo-sans-web",sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #DD3545;
    position:relative;
}
.accordions_title span img{
	position: absolute;
    right: 0;
    top: 5px;
}
.accordions_title.active span img{
	transform: rotate(90deg);
}
.card__content{
	height:auto;
	top: -40px;
}
}
@media (min-width: 1600px) {
	.card-image {
		.img-responsive {
			object-position: center;
			height: auto;
		}
	}
	.card__content {
        top: -30px !important;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
	.cards {
		gap: 14px;
		width: 80.75VW;
		grid-row-gap: 30px;
	}
	.card-image {
		.img-responsive {
			object-position: center;
			height: auto;
		}
	}
}
@media (min-width: 768px){
	.cards__item {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}
@media (min-width: 1440px) {
    #intro-head-container {
        max-width: 1320px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    section#intro-head-container {
        align-items:center;
    }
    section#meet-the-team-section {
        max-width: 100%;
    }
    .cards {
        width: 90.75VW;
    }
    .team #intro-head-container #intro-head-image img {
        margin: 0 auto;
    }
    #intro-head {
        margin-top: 0;
        margin-left:0 !important;
        padding-left:20px
    }
    div#intro-head-image {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 60px;
    }
    section#meet-the-team-section .card__content:before {
        top: 0.8rem;
        left: 18%;
    }
    .card__title{
        font-size: 18px;
        line-height: 18px;
    }
    .card__subtitle {
        font-size: 13px;
        line-height: 23px;
        margin-bottom: 10px;
    }
    #meet-the-team-section hr {
        margin: 0 0 10px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    section#intro-head-container {
        display: flex !important;
    }
    #intro-head {
        margin-left: 40px;
        margin-top: 80px;
    }
}
@media (min-width: 990px) and (max-width: 1280px) {
    #intro-head {
        margin-left: 40px !important;
    }
}