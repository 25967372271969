.AboutHero{
    @apply relative pb-3;
}
.AboutImages{
    @apply 
        w-6/12 
        absolute 
        h-full 
        right-1/2 
        min-w-[700px];

    img{
        @apply
            absolute
            inline-block;
    }

    img:first-child{ 
        @apply -left-15 transform -top-7; 
    }
    img:nth-child(2){ 
        @apply -left-3 ;
        top: calc( var(--first-height) + #{$spacing-7} );
    }

    img:nth-child(3){
        @apply -right-0 -top-2;
    }
    img:nth-child(4){
        @apply bottom-0;
        right:-8.333%;
    }


    @screen lg{
        @apply w-5/12;
    }
 
    @screen md+{
        right: 60%;
    }

    @screen md{
        @apply
            absolute
            w-screen
            min-w-0
            right-1/2
            transform
            translate-x-1/2;
        height: calc( var(--scroll-height) * 1.4); //extra for scale down of images


        img{
            @apply
                transform
                scale-75;
        }

        img:first-child{
            @apply 
                top-auto
                bottom-1
                right-2/3 
                left-auto;
        }

        img:nth-child(2){
            @apply 
                -top-3
                right-1/2
                left-auto;
        }

        img:nth-child(3){
            @apply 
                -top-8
                -right-4
                left-auto;
        }

        img:last-child{
            @apply right-auto left-1/2 -bottom-3;
        }

    }

    
    
}

.AboutImages-wrap{
    min-height: calc( var(--first-height) + var(--last-height) * 2.5)
}

.AboutCutoutBackground {
    @screen sm {
        background-position: center !important;
    }
}

@screen md{
    .AboutImages-wrap{
        min-height: var(--scroll-height);
    }
}

.AboutIntro{
    @apply
        pt-12
        w-4/12
        mr-[8.33%];
    
    @screen xl{
        @apply w-5/12 mr-0;
    }

    @screen md+{
        @apply w-6/12;
    }
    @screen md{
        @apply w-full mt-4;
        padding-top: calc(var(--scroll-height) * 1.4);
    }
}



.OurPeople{

    overflow-x: hidden;

    &-image{
        @apply
            absolute 
            right-0 
            -top-2 
            inline-block 
            w-1/2 
            rounded 
            max-w-[800px];

        @screen md+{
            @apply -right-10;
        }

        @screen md{
            @apply
                relative
                right-auto
                w-full;
        }
    }
}