.Testimonials{
    @apply
        relative
        flex
        w-10/12
        mx-auto
        gap-2;


    &-copy{
        @apply flex-1 my-5 text-gray-800 relative;
        @include tab(right);
    }

    &-copyItem{
        @apply 
            absolute 
            bg-white 
            px-5 
            transition-opacity 
            opacity-0 
            duration-1000
            rounded-b-lg;
        
        &.is-active{
            @apply opacity-100;
        }
    }

    &-image{
        @apply 
            flex-1 
            overflow-hidden 
            rounded-lg
            relative
            h-50;

        img{
            @apply 
                object-cover 
                w-full 
                h-full
                rounded-lg
                absolute
                inset-0;
        }
    }

    //Next & Prev Buttons
    &-nav{
        @apply 
            absolute
            bottom-0
            left-5
            flex
            gap-1
            transform
            translate-y-1/2;
    }

    &-prev,
    &-next{
        @apply
            bg-red
            rounded-full
            w-5
            h-5
            flex
            justify-center
            items-center
            transform
            rotate-180
            transition-opacity;
        
            &::before{
                content: inline-svg('<svg width="10" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.95591 6.80024.911631 2.17569C.43005 1.69934.428205.92522.90751.446614c.4793-.4786672 1.2583-.48044 1.73988-.004157L9.07568 6.80024c.48145.4775.48299 1.25413.00123 1.73206l-6.4294 6.3589c-.48152.4763-1.26051.4746-1.739877-.0041-.479304-.4786-.47746-1.2527.004121-1.7291L5.95591 8.5323c.61043-.47368.61043-1.26442 0-1.73206Z" fill="#000"/></svg>');
                @apply
                    block;
                line-height: 0;
            }
        
        &.is-disabled{
            @apply opacity-100;
        }
        
    }

    &-next{
        @apply transform rotate-0;
    }


    @screen lg{
        @apply flex-col-reverse gap-2;

        &-copy{
            @apply my-0 mx-5 box-content px-0 transition-all duration-1000;
            @include tab(top);
            min-height: var(--copy-height);
        }

        &-image{
            @apply flex-none;
            height: 35vh;
        }

        &-copyItem{
            @apply px-3;
        }

        &-nav{
            @apply
                bottom-auto
                top-0
                right-3
                left-auto
                z-10
                -translate-y-3/4
        }
    }

    @screen md{
        @apply w-full;
    }
}