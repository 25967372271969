/*******************************
JOIN TABBED
*******************************/
.JoinTabbed{

    &-tabs{
        @apply
            flex
            w-10/12
            mx-auto
            relative
            pt-5
            pb-4
            px-0;
        
        @screen xl{
            @apply w-11/12;
        }
        @screen lg{
            @apply w-full;
        }
        @screen md{
            @apply w-10/12;
        }
    }
    
    &-tab{
        @apply
            flex
            flex-col
            w-1/3
            border-r
            border-[#ebeff2]
            px-5
            block
            justify-between;

        color: #c2d1d9;

        .btn{
            @apply mt-2 self-start;
        }

        @screen lg{
            @apply px-3;
        }
    }

    &-text {
        min-height: 85px;
    }

    &-tab:last-child{
        @apply border-none;
    }

    &-tabHeader{
        @apply 
            text-[24px] 
            text-[#424B5A] 
            font-semibold 
            font-montserrat
            pb-2;
        @screen md {
            font-size: 21px;
        }
    }

    &-caret{
        @apply
            absolute
            top-0
            transform
            -translate-y-full;
        left:10%;
        transition: all .5s $easeInOutCubic;
        

        &::before{
            content: inline-svg('<svg width="37" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30.2062 17.4757 23.4467 3.41994c-1.8426-3.685247-7.1016-3.685242-8.9442 0L7.743 17.4757h22.4632Z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="m23.9746 4.47566 3.382 6.27114c1.9618 3.6375 5.5284 6.1328 9.618 6.7289h-13V4.47566ZM13.9746 4.47566l-3.382 6.27114c-1.96174 3.6375-5.52841 6.1328-9.617991 6.7289H13.9746V4.47566Z" fill="#fff"/></svg>');
            @apply block;
            line-height: 0;
        }


    }


    &-images{
        @apply  relative
                h-50;
    }
    &-image{
        @apply 
            absolute
            object-cover
            w-full
            h-[844px]
            object-center
            rounded-lg;
    }

    &-overlap {
        @apply  relative
                -top-5;
    }

    &-tabsMeta {
        @apply  absolute
                left-1/2
                -translate-x-1/2
                -translate-y-5
                bottom-0
                flex
                w-10/12
                justify-between;
    }

    &-callout {
        @apply  max-w-[555px]
                z-10
                opacity-80
                rounded-lg
                bg-blue
                transition-all;
        @screen md {
            width:100%
        }

        h4.callout-title {
            @apply  text-white
                    font-montserrat
                    text-50
                    font-thin
                    tracking-normal
                    leading-[58px]
                    p-3;
            @screen md {
                @apply 
                    heading-jb-5
                    text-white;

                    font-weight: 500;
                    padding-right: 150px;
                    min-height: 140px;

            }
        }
    }

    &-loader {

        /* @apply  absolute
                right-4
                top-1/2; */

        > div {
            @apply  relative 
                    translate-y-6;
        }

        svg {
            @apply  w-10
                    h-10
                    rotate-90;
            overflow: initial;
            
            circle {
                @apply  stroke-[5px]
                        fill-transparent;

                &:nth-child(1) {
                    stroke: rgba($white, 0.3);
                }

                &:nth-child(2) {
                    @apply  stroke-white
                            relative;
                    z-index: 1;
                }

                &:nth-of-type(2) {
                    stroke-dashoffset: calc(100px * 6);
                    stroke-dasharray: calc(100px * 6);
                    animation: circle1 5s linear;
                }
            }
        }

        @keyframes circle1 {
            from {
                stroke-dashoffset: calc((100px * 6) - ((100px * 6) * 0) / 100);
            }
            to {
                stroke-dashoffset: calc((100px * 6) - ((100px * 6) * 20) / 100);
            }
        }

        @screen md {
            position: absolute;
            right: 10px;
            top: -30px;
            z-index:999999;
        }
    }

    @screen md{
        .box{
            @apply bg-transparent p-0;
        }

        .flickity-viewport{
            @apply
                bg-white
                rounded
                px-3
                py-3
                box-content;
        }

        &-text{
            min-height: unset;
        }


        &-tabs{
            @apply 
                block
                px-0;

            &:after{
                content: 'flickity';
                display: none;
            }
        }

        &-tab{
            @apply 
                w-full
                border-none
                px-3;
        }

        &-caret{
            left: 10% !important; // caret doesn't move anymore
        }
        
        &-image{ 
            @apply
                rounded-none
                h-[320px];
        }

        &-images{
            @apply
                h-50;
        }

        .btn {
            // width: 100%;
            // text-align: left;
        }

    }
    
}

.Footer-myAEGcta {
    .JoinTabbed {
        &-images {
            @apply  max-h-[470px]
                    h-[470px]
                    overflow-hidden;
        }
        &-tabsMeta {
            @apply  bottom-[unset]
                    top-1/2;
        }

        &-loader {
            @apply  absolute
                    -right-4
                    top-1/2
                    -translate-y-5;

        }

        &-tabs {
            @apply  min-h-[250px]
                    -mt-[7.25rem]
                    py-3;
        }

        &-tab {
            @apply  justify-start
                    items-start;
        }
        &-tabHeader {
            @apply  text-[#424B5A]
                    font-montserrat
                    text-24
                    font-semibold
                    tracking-normal
                    leading-[29px]
                    pb-[17px];
        }

    }
}




/*******************************
JOIN GROWING
*******************************/
.JoinGrowing{
    @apply
        text-white
        bg-right
        bg-no-repeat
        bg-contain
        flex;
    min-height: 613px;  
    background-image: url( $dist + '/map-white.png' );

    @screen retina{
        background-image: url( $dist + '/map-white.png' );
    }

    @screen lg {
        background-image: none;
    }

    @screen md+ {
        @apply  pt-10;
        background-position: right bottom 24%;
    }

    @screen sm {
        @apply  pt-6;
        background-position: right bottom 6%;
    }
}


/*******************************
VALUES CTA
*******************************/
.ValuesCTA{
    @apply relative;

    &-image{
        @apply 
            shadow 
            rounded-lg 
            h-full
            max-h-[330px]
            w-full 
            object-cover
            object-top;

        @screen md {
            @apply  rounded-none;
        }
    }

    &-copy{

        @include caret('right');
        
        @apply
            absolute 
            flex
            flex-col
            items-start
            top-1/2 
            transform 
            -translate-y-1/2 
            left-6 
            shadow
            w-[400px]
            px-[45px]
            py-[40px];

        &::before {
            top: 0 !important;
            left: 100%;
            transform: translateX(-10px) translateY(60px) rotate(90deg) !important;
        }

        h5 {

        }

        @screen lg{
            @apply left-3 w-6/12;
        }

        @screen md{
            @apply  
                w-11/12 
                mx-auto
                relative
                left-[unset];
            @include caret('top');
        }
    }

    &-image {
        @apply  h-full
                min-h-[330px]
                overflow-x-hidden;
    }
}


.TwoUpCTA{
    @apply flex justify-center flex-wrap;

    &-item{
        @apply
            bg-cover
            bg-center
            transition-all
            transform
            scale-100
            z-10;
        background-image: var(--img);

        @screen retina{
            background-image: var(--retina);
        }

        &:hover{
            @apply scale-105 z-20;
        }
    }

    div:first-child .TwoUpCTA-item{
        @apply rounded-l;

        @screen md+{
            @apply rounded-bl-none rounded-t;
        }
    }
    div:last-child .TwoUpCTA-item{
        @apply rounded-r;

        @screen md+{
            @apply rounded-tr-none rounded-b;
        }
    }

    &-info{
        @apply 
            absolute
            bottom-0
            top-auto
            left-0
            w-3/4
            bg-blue
            bg-opacity-80
            rounded-tr
            py-4
            px-5
            text-white
            font-light;

        h5 {
            @screen xl+ {
                @apply  heading-jb-3;
            }

            @screen md+ {
                @apply  heading-jb-4;
            }

            @screen  sm {
                @apply  heading-jb-6;
            }

            @apply  text-white; 
        }

        p{
            @apply line-clamp-3
                    text-white;

            @screen sm {
                @apply  text-white;
            }
        }

        @screen lg+{
            @apply w-10/12 py-2 px-3;
        }

        @screen md+{
            @apply w-9/12 py-2 px-3;
        }

        @screen md{
            @apply text-14;
        }
        
        @screen sm{
            @apply w-full py-2 px-2;
        }
    }
}