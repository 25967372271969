.HistoryHero{
    @apply
        bg-blue
        bg-no-repeat
        bg-right
        justify-center
        relative;
    background-image: url($dist + '/Astriped.svg');

    @screen md+{
        @apply flex flex-col items-end py-10 bg-right-bottom space-y-3;
    }

    &-image{
        @apply
            absolute
            top-1/2
            right-0
            transform
            -translate-y-1/2
            rounded-l
            w-4/12;  
        
        @screen lg{
            @apply w-5/12;
        }

        @screen md+{
            --containerSize: #{$md};
            @apply 
                static 
                transform-none 
                rounded-l-lg;
            width: calc( var(--containerSize) + (100vw - var(--containerSize))/2 )
        }
        @screen md{ --containerSize: #{$sm} }
        @screen sm{ --containerSize: 95% }
    }
}


.Timeline{
    @apply relative;

    &-image{
        @apply
            h-screen
            w-full
            object-cover; 
        
        &:first-of-type{
            margin-top: -100vh;
        }
    }
    &-sticky{
        @apply 
            h-screen 
            z-50
            top-0;
        position: -webkit-sticky;
        position: sticky;
    }

    &-box{
        @apply
            absolute
            top-1/2
            py-5
            pr-6
            pl-[115px]
            transform
            -translate-y-1/2
            rounded-r
            shadow
            bg-white
            w-7/12;

            span{ @apply font-medium }

            p{ @apply font-light leading-relaxed}

        @screen lg{
            @apply w-1/2;
        }


        &--static{
            @apply
                p-3
                bg-white
                rounded-lg
                shadow
                -my-3
                relative
                z-10;
        }
    }

    &-dots{
        @apply
            left-6
            top-5
            bottom-0
            absolute
            space-y-1;
    }

    &-dot{
        @apply
            block
            w-1
            h-1
            border-2
            border-gray-300
            bg-white
            rounded-full;

        &.is-active{
            @apply
                bg-red
                border-red;
        }
    }


    @screen md{
        &-image{
            aspect-ratio: 1;
            @apply h-auto;
        }
        &-image:first-of-type{
            @apply mt-0;
        }
        &-sticky{
            @apply hidden;
        }
    }
}