// Crop line height from top and bottom of an element while maintaining it between lines of text
@mixin lhCrop($line-height) {
    &::before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        margin-top: calc((1 - #{$line-height}) * 0.5em);
    }
}
@mixin retina {
    @media only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min-device-pixel-ratio: 2) {
        @content;
    }
}

@mixin tab($position: "right") {
    @apply relative rounded;
    @if $position == top{
        @apply rounded-t-none;
    }
    @if $position == right {
        @apply rounded-r-none; 
    }
    @if $position == bottom{
        @apply rounded-b-none;
    }
    @if $position == left{
        @apply rounded-l-none;
    }

    $beforeMaskPos: null;
    &::before {
        content: "";
        @apply absolute bg-white;
        @if $position == top{
            @apply h-2 w-3/4 -top-2 -left-2;
            $beforeMaskPos: bottom left;
        }
        @if $position == right {
            @apply w-2 h-3/4 -top-2 -right-2; 
            $beforeMaskPos: top left;
        }
        @if $position == bottom{
            @apply h-2 w-3/4 -bottom-2 -left-2;
            $beforeMaskPos: top left;
        }
        @if $position == left{
            @apply w-2 h-3/4 -top-2 -left-2;
            $beforeMaskPos: top right;
        }
        mask-image: radial-gradient(
            circle 19.5px at $beforeMaskPos,
            transparent 0,
            transparent 19.5px,
            black 20px
        );
        
    }
    $afterMaskPos: null;
    &::after {
        content: "";
        @apply absolute bg-white;
        
        @if $position == top{
            @apply h-2 w-3/4 -top-2 -right-2;
            $afterMaskPos: bottom right;
        }
        @if $position == right {
            @apply w-2 h-3/4 -bottom-2 -right-2; 
            $afterMaskPos: bottom left;
        }
        @if $position == bottom{
            @apply h-2 w-3/4 -bottom-2 -right-2;
            $afterMaskPos: top right;
        }
        @if $position == left{
            @apply w-2 h-3/4 -bottom-2 -left-2;
            $afterMaskPos: bottom right;
            
        }
        mask-image: radial-gradient(
            circle 19.5px at $afterMaskPos,
            transparent 0,
            transparent 19.5px,
            black 20px
        );
        
    }
}

@mixin caret($position: "top") {
    position: relative;

    &::before{
        content: inline-svg('<svg width="37" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30.2062 17.4757 23.4467 3.41994c-1.8426-3.685247-7.1016-3.685242-8.9442 0L7.743 17.4757h22.4632Z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="m23.9746 4.47566 3.382 6.27114c1.9618 3.6375 5.5284 6.1328 9.618 6.7289h-13V4.47566ZM13.9746 4.47566l-3.382 6.27114c-1.96174 3.6375-5.52841 6.1328-9.617991 6.7289H13.9746V4.47566Z" fill="#fff"/></svg>');
        @apply 
            absolute 
            top-0
            left-1/2
            transform
            -translate-y-full
            rotate-0
            -translate-x-1/2;
        line-height: 0;
    }
    @if $position == "right" {
        &::before{
            @apply
                left-full
                top-1/2
                -translate-y-1/2
                rotate-90;
            --tw-translate-x: -10px
        }
    }
    
    @if $position == "bottom" {
        &::before{
            @apply
                left-1/2
                top-full
                rotate-180
                translate-y-0;
        }
    }
    @if $position == "left" {
        &::before{
            @apply
                left-0
                top-1/2
                -rotate-90
                origin-bottom;
                --tw-translate-y: -17px
        }
    }

}

// Replace letters
@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(
                str-slice($string, $index + str-length($search)),
                $search,
                $replace
            );
    }

    @return $string;
}

// Encode symbols
@function url-encode($string) {
    $map: (
        "%": "%25",
        "<": "%3C",
        ">": "%3E",
        " ": "%20",
        "!": "%21",
        "*": "%2A",
        "'": "%27",
        '"': "%22",
        "(": "%28",
        ")": "%29",
        ";": "%3B",
        ":": "%3A",
        "@": "%40",
        "&": "%26",
        "=": "%3D",
        "+": "%2B",
        "$": "%24",
        ",": "%2C",
        "/": "%2F",
        "?": "%3F",
        "#": "%23",
        "[": "%5B",
        "]": "%5D",
    );

    $new: $string;

    @each $search, $replace in $map {
        $new: str-replace($new, $search, $replace);
    }

    @return $new;
}

// Format the SVG as a URL
@function inline-svg($string) {
    @return url("data:image/svg+xml,#{url-encode($string)}");
}
