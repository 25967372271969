.RelatedServices{
    // @apply mt-10 pt-10;

    // @screen md {
    //     @apply  mt-20;
    // }

    &-title{
        @apply
            bg-white
            rounded-t-lg
            pt-1
            pl-2
            pr-4            
            inline-block
            mb-0;

        &::before{
            content: none;
        }

        &::after{
            // .tab-b::after
            // @apply  shadow-relatedMoreContainer;
            content: '';
            bottom: -20px;
            right: -20px;
            height: 40px;
            width: 40px;

            border: 1px solid white;

            // mask-image: radial-gradient(circle 8.5px at top right, transparent 0, transparent 9.5px, black 10px);
        }

        // .tab-b::after
    }

    &-list{
        @apply
            bg-white
            rounded
            rounded-tl-none
            p-3
            flex
            flex-wrap
            gap-3;
        
        @screen lg+ {
            display:grid;
            @apply grid-cols-2;
        }

        @screen md{
            @apply grid-cols-1;
        }

        li{
            @apply flex-1;
        }
    }

    a{
        @apply
            block
            relative
            rounded
            overflow-hidden;
    }

    &-serviceName{
        @apply
            absolute
            rounded-full
            p-1
            px-2
            bg-white
            left-2
            bottom-2
            font-medium;
    }    
}

.ServiceDetail {
    &-feature {
        z-index: 999;
        > .Card {
            @apply  h-full
                    shadow-sm;
        }
    }
}

.ServiceFeatures {
    margin-bottom: -80px;
}

