#{$all-buttons},
.btn{
    @apply 
        bg-red
        text-white
        text-center
        py-1
        px-2
        inline-block
        rounded-full
        text-[12px]
        font-medium
        font-azo; //12px

    &:hover,
    &:focus{
        box-shadow:
          0px 0.2px 0.2px rgba(0, 0, 0, 0.074),
          0px 0.6px 0.5px rgba(0, 0, 0, 0.15),
          0px 1.5px 1.4px rgba(0, 0, 0, 0.226),
          0px 5px 5px rgba(0, 0, 0, 0.3)
        ;
    }
    
    &.btn--inverse {
        @apply  border
                border-red
                rounded-full
                bg-white
                text-red;

                text-decoration: none !important;
                color: #c30000 !important;
    }

    &.btn--large,
    &.btn--lg{
        @apply 
            py-[.75rem]
            px-4
            text-[.9375rem]; //15px
    }

    &.btn--medium,
    &.btn--md{
        @apply 
            py-1
            px-2
            text-[12px];
    }

    &.btn--small,
    &.btn--sm{
        @apply py-1 px-2; 
    }

    &.btn--text{
        @apply
            bg-transparent
            text-red
            rounded-none
            text-16
            text-left
            font-normal
            px-0;
        img{
            @apply transition-all
                    transform
        }
        &:hover,
        &:focus{
            @apply shadow-none;

            img{
                @apply
                    
                    translate-x-1;
            }
        }

        img:last-child{
            @apply ml-1;
        }
    }

    img{
        display: inline-block;
    }
    img:first-child{
        @apply mr-1;
    }
    img:last-child{
        @apply ml-[15px];
    }

}

.btn-arrow{
    &--blue {
        &:after{
            content: url($dist + '/arrow-right.svg');
            @apply ml-1 inline-block;
            transition: all .5s ease;
            transform: translateX(0px);
        }

        &:before {
            content: url($dist + '/arrow-right.svg');
            @apply pl-1 hidden;
            transition: all .5s ease;
            transform: translateX(0px) scaleX(-1);
        }
    }

    &--white {
        &:after{
            content: url($dist + '/right-arrow--white.svg');
            @apply ml-1 inline-block;
            transition: all .5s ease;
            transform: translateX(0px);
        }

        &:before {
            content: url($dist + '/right-arrow--white.svg');
            @apply pl-1 hidden;
            transition: all .5s ease;
            transform: translateX(0px) scaleX(-1);
        }
    }

    &--red {
        &:after{
            content: url($dist + '/right-arrow--red.svg');
            @apply ml-1 inline-block;
            transition: all .5s ease;
            transform: translateX(0px);
        }

        &:before {
            content: url($dist + '/right-arrow--red.svg');
            @apply pl-1 hidden;
            transition: all .5s ease;
            transform: translateX(0px) scaleX(-1);
        }
    }

    &-small{
        &--red {
            &:after{
                content: url($dist + '/right-arrow-small--red.svg');
                @apply ml-1 inline-block;
                transition: all .5s ease;
                transform: translateX(0px);
            }
    
            &:before {
                content: url($dist + '/right-arrow-small--red.svg');
                @apply pl-1 hidden;
                transition: all .5s ease;
                transform: translateX(0px) scaleX(-1);
            }
        }
        &--reverse {
            &:after {
                @apply  hidden;
            }
    
            &:before {
                @apply  inline-block;
            }
        }  
    }

    &--reverse {
        &:after {
            @apply  hidden;
        }

        &:before {
            @apply  inline-block;
        }
    }    

    &--download {
        @apply  flex
                items-center
                justify-between;

        &:after {
            content: url($dist + '/down-arrow--download.svg');
            @apply  w-2 ml-1;
        }
    }
}

a[class^='btn-arrow'],
button[class^='btn-arrow'] {
    &:hover::after{
        transform: translateX(3px);
    }
}

.btn--close{
    @apply
        rounded-none
        bg-transparent
        bg-no-repeat
        bg-center
        p-1
        w-2
        h-2;
        text-indent: -9999px;
        background-image: url($dist + '/close.svg');

    &:hover,
    &:focus{
        @apply shadow-none;
    }
}

.btn--next,
.btn--prev,
.flickity-prev-next-button{
    @apply 
        appearance-none
        w-5
        h-5
        bg-no-repeat
        bg-center
        p-0
        mx-auto
        overflow-hidden
        shadow-lg
        justify-center
        items-center
        inline-flex
        cursor-pointer;

    &::before{
        @apply block transform -rotate-90;
        content: url($dist + '/navArrowUp.svg');
    }

    svg{
        display: none;
    }

    &:disabled{
        @apply
            opacity-100
            cursor-not-allowed;
    }
}

.btn--next::before,
.flickity-prev-next-button.next::before{
    @apply rotate-90;

}

/*************************************
* Auto space out button when after a p
*************************************/
p + .btn{
    @apply mt-2;
}