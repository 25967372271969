.NrpOverviewResources {
    &-text {
        h2 {
            @apply  pt-[5px];
        }
        
        p {
            @apply  text-16
                    font-azo
                    font-normal;

            &:first-of-type {
                @apply  text-20
                        leading-7;
            }
        }
    }
}