$dropdownTransition: .4s $easeOutBack;
a.logo{
    @include size(155px,41px);
    @apply block;
    text-indent: -9999px;
    background: url($dist + '/logo.svg');
}

.Nav{
    @apply  bg-blue
            sticky
            z-50
            top-0
            py-[15px]
            w-full;

    z-index:1000;
    box-shadow: 0 8px 8px rgba(0,0,0,.25);
    
    &>nav{
        @apply  align-middle 
                flex 
                justify-between;
    }

    h1{
        @apply self-center ml-2;
    }

    &-main{
        @apply flex;

        li{
            @apply flex flex-col;

            &:hover{

                a::after{
                    content:'';
                    @apply 
                        bg-[#33667e]
                        inset-x-1
                        inset-y-0
                        absolute
                        z-0;
                }
            }
        }

        a{
            @apply 
                relative
                flex
                items-center 
                px-[23px]
                py-[13px]
                no-underline
                text-white
                font-azo
                font-medium
                text-12
                transform
                skew-x-[-23deg]
                border-r;

            border-color: rgba(255,255,255,0.1);

            span{
                @apply
                    transform
                    skew-x-[23deg]
                    pointer-events-none
                    relative
                    z-10;
            }

            
        }

        li:last-child a,
        li:nth-last-child(2) a{
            @apply border-r-0;
        }



        /*************************************
        * Hamburger sidebar nav
        *************************************/
        @screen lg {
            @apply 
                flex-col
                fixed
                h-screen
                -right-full
                top-0
                bg-blue
                z-50;

            &.is-open{
                @apply
                    right-0;
            }

            a{
                @apply 
                    transform-none
                    border-r-0
                    border-transparent
                    border-l-[7px]
                    block
                    px-2
                    py-0
                    text-26
                    font-light
                    font-montserrat;
                

                span{
                    @apply 
                        transform-none
                        border-b
                        flex
                        items-center
                        py-3;

                        border-color: rgba(255,255,255,0.1);
                }

                &.is-open{
                    @apply
                        border-red
                        bg-blue-mid;
                    
                    padding-bottom: calc( var(--dropdownHeight) + #{$spacing-2} );
                }
            }

            .Nav-myBtn{
                @apply my-0 ml-0;

                a{
                    @apply ml-0 w-full;
                }

                a::before{
                    content: none;
                }

                span{
                    @apply w-full;
                }
            }
        }
    }

    &-myBtn{
        @apply
            bg-red
            pl-0
            -my-[15px]
            ml-6;

        a{
            @apply 
                -ml-5
                pl-2 
                bg-red 
                h-full
                flex
                relative;

            &::before{
                content: '';
                @apply
                    block
                    w-2
                    h-3/4
                    translate-x-1
                    bg-[#ce3234];

            }

            span{
                @apply
                    bg-[#ce3234]
                    flex
                    h-3/4
                    pr-5
                    pl-4
                    items-center;
                border-radius: 4px;
            }
        }

        &:hover a {
            span, &:before {
                @apply  bg-red-bright;
            }

            &::after{ 
                content: none !important 
            }
        }

        
    }


    &-hamburger{
        @apply hidden;

        @screen lg{
            @apply
                block
                rounded-none
                px-1
                bg-none
                bg-transparent;

        }
        &:hover,
        &:focus,
        &:active{
            box-shadow: none !important;
        }

        &:hover{

            path{
                @apply origin-right;
            }

            path:first-child{
                animation: hamburgerHover .2s ease-in-out 0s;
            }
            path:nth-child(2){
                animation: hamburgerHover .2s ease-in-out .05s;
            }
            path:nth-child(3){
                animation: hamburgerHover .2s ease-in-out .1s;
            }
        }
    }

    @keyframes hamburgerHover {
        0% {transform: scaleX(1)}
        50%{transform: scaleX(.6)}
        100% {transform: scaleX(1)}
    }

    @screen lg {
        @apply  relative
                px-[12px]
                py-[15px]
                w-full
                z-[9999];

        nav {
            &#wwd, &#about, &#news-resourecs {
                @apply  block;
            }
        }

        &-main {
            &.is-open {
                @apply  left-0
                        top-[64px];
            }

            a {
                @apply  text-[1.5rem];

                &.is-open {
                    padding-bottom: calc(var(--dropdownHeight) + 2.25rem);
                }

                span {
                    @apply  py-[1.5rem];
                }
            }
        }

        

        ul.Nav {
            &-main {
                &.is-open {
                    @apply  absolute
                            h-auto;
                }
            }
        }

        .hamburger {
            &-inner {
                @apply  block
                        top-[15%]
                        mt-[-1px]
                        w-[34px]
                        h-[2px]
                        bg-white
                        relative;

                transition: 0.4s;

                &:before {
                    @apply  absolute
                            h-[2px]
                            bg-white
                            left-0
                            top-[-12px]
                            w-3/5
                            ml-[13px];

                    transition: 0.4s;
                }

                &:hover {
                    &:before {
                        @apply  ml-0
                                w-full;
                    }
                }
            }
        }

        &-hamburger {
            &.open {
                .hamburger-inner {
                    @apply  translate-x-[-50px]
                            bg-transparent
                            shadow-none;

                    &:before {
                        @apply  w-full
                                ml-0;
                        transform: rotate(45deg) translate(36px, -20px);
                    }

                    :after {
                        @apply  w-full;
                        transform: rotate(-45deg) translate(37px, 20px);
                    }
                }
            }
        }

        .container {
            @apply  max-w-none
                    px-2
                    py-1;
        }

        .Dropdown {
            @apply  pr-0
                    flex-row;

            &-col {
                @apply  flex
                        flex-col
                        pr-6;
            }

            &-wrap {
                @apply  max-w-full
                        w-[90%]
                        mx-auto
                        rounded-lg;

                
                box-shadow: 0 0 34px 0 #000000;
            }

            &-bg {
                @apply  top-0
                        pl-0
                        mx-auto
                        h-full;

                &.caret-top {
                    &:before {
                        @apply  hidden;
                    }
                }
            }


            
        }


    }

    @screen sm+ {
        &-myBtn {
            a {
                @apply  bg-[#003052]
                        relative;

                &:after {
                    @apply  hidden;
                }

                span {
                    @apply  bg-[#003052]
                            ml-0
                            py-[1.5rem]
                            px-0;

                    &:after {
                        @apply  hidden;
                    }
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.2);

                    span {
                        @apply  bg-transparent;
                    }
                }
            }
        }

        .Dropdown {
            @apply  pr-0
                    flex-col;

            &-wrap {
                @apply  max-w-full
                        w-11/12;
            }

            &-col {
                @apply  p-[1rem]
                        w-full;
            }
        }
    }
}






/*************************************
* Dropdowns
*************************************/

.Dropdown-wrap{
    @apply absolute
            w-full
            top-1
            bg-opacity-25;
    height: var(--dropdownHeight);
    width: var(--dropdownWidth);
    left: var(--dropdownLeft);
    transform: translateX(-50%) rotateX(0deg);
    transform-origin: top center;
    z-index: 100;
    opacity: 1;
    transition: all $dropdownTransition;

    &.is-hidden{
        transform: translateX(-50%) rotateX(-25deg);
        visibility: hidden;
        opacity: 0;
    }

    @screen md{
        max-width: 250px;
    }
}

.Dropdown-bg{
    @apply  
        h-full
        bg-white
        rounded
        shadow-2xl
        top-sm;
}


.Dropdown{
    @apply
        absolute
        flex
        space-x-5;

    opacity: 1;
    transition: all .4s 50ms ease;

    &[hidden]{
        opacity: 0;
        pointer-events: none;
        transition: all 10ms 0s ease;
    }

    //Subsection headings
    h5 a{
        @apply
            text-blue
            pb-1
            font-medium
            font-azo
            text-16
            pt-0
            border-b-2
            border-blue-light;

        &::after{
            content: url($dist + '/arrow-right--red.svg');
            @apply ml-1 inline-block;
            transition: all .5s ease;
            transform: translateX(0px);
        }
        &:hover::after{
            content: url($dist + '/arrow-right--brightRed.svg');
            
        }
    }

    

    //Nav links
    a{
        @apply  no-underline 
                block 
                whitespace-nowrap
                text-blue-600
                text-14
                pt-1;

        &:hover{
            &:after {
                @apply  w-full;
            }
        }
    }

    //Columns
    &-col{
        @apply 
            flex
            flex-col
            justify-between
            p-2;
        flex:1 0 0;


        ul {
            //Space between subsections
            + ul{
                @apply mt-2;
            }

            li {
                > a {
                    @apply  relative
                            w-max;

                    &:after {
                        @apply  absolute
                                bg-red-bright
                                w-0
                                h-[2px]
                                left-0
                                bottom-[-2px];

                        transition: 0.4s;
                        content: ' ';
                    }

                    &:hover {
                        &:after {
                            @apply  w-full;
                        }
                    }
                }
            }
        }

    }    
    

    /*************************************
    * Hamburger Dropdown
    *************************************/
    @screen lg{
        @apply flex-col space-x-0;


        &-bg{
            @apply top-3;
        }

        &-wrap{
            top: var(--dropdownTop);
            transition: none;
        }
    }
        
}

