// --------------------------------------------------------------
// Playback progress
// --------------------------------------------------------------

// Offset the range thumb in order to be able to calculate the relative progress (#954)
$plyr-progress-offset: $plyr-range-thumb-height;

.plyr__progress {
  left: calc(#{$plyr-progress-offset} * 0.5);
  margin-right: $plyr-progress-offset;
  position: relative;

  input[type='range'],
  &__buffer {
    margin-left: calc(#{$plyr-progress-offset} * -0.5);
    margin-right: calc(#{$plyr-progress-offset} * -0.5);
    width: calc(100% + #{$plyr-progress-offset});
  }

  input[type='range'] {
    position: relative;
    z-index: 2;
  }

  // Seek tooltip to show time
  .plyr__tooltip {
    font-size: $plyr-font-size-time;
    left: 0;
  }
}

.plyr__progress__buffer {
  -webkit-appearance: none; /* stylelint-disable-line */
  background: transparent;
  border: 0;
  border-radius: 100px;
  height: $plyr-range-track-height;
  left: 0;
  margin-top: calc((#{$plyr-range-track-height} / 2) * -1);
  padding: 0;
  position: absolute;
  top: 50%;

  &::-webkit-progress-bar {
    background: transparent;
  }

  &::-webkit-progress-value {
    background: currentColor;
    border-radius: 100px;
    min-width: $plyr-range-track-height;
    transition: width 0.2s ease;
  }

  // Mozilla
  &::-moz-progress-bar {
    background: currentColor;
    border-radius: 100px;
    min-width: $plyr-range-track-height;
    transition: width 0.2s ease;
  }

  // Microsoft
  &::-ms-fill {
    border-radius: 100px;
    transition: width 0.2s ease;
  }
}

// Loading state
.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(
    -45deg,
    $plyr-progress-loading-background 25%,
    transparent 25%,
    transparent 50%,
    $plyr-progress-loading-background 50%,
    $plyr-progress-loading-background 75%,
    transparent 75%,
    transparent
  );
  background-repeat: repeat-x;
  background-size: $plyr-progress-loading-size $plyr-progress-loading-size;
  color: transparent;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: $plyr-video-progress-buffered-background;
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: $plyr-audio-progress-buffered-background;
}
