.JoinUsBlock{
    @apply
        flex
        flex-row-reverse
        flex-wrap
        justify-between;
    gap: calc( 100% / 12 );

    @screen md{
        @apply 
            gap-4 
            flex-col-reverse 
            mt-5;
    }

    &>div:first-child{
        @apply 
            flex 
            flex-col 
            justify-center 
            pt-2
            mr-auto 
            ml-0;

        @screen md{
            @apply pt-3;
        }
    }

    &-image{
        @apply 
            relative 
            overflow-hidden;
        border-radius: 0 90px 0 0;

        @screen md{
            @apply ml-4 w-auto;
            border-radius: 90px 0 0 90px;
        }

        img{
            @apply h-full w-full object-cover;
        }
    
        &::before{
            @apply absolute inset-0 content-[''];
            box-shadow: inset 0px 8px 30px rgba(0, 0, 0, 0.7);
        }
    }

    p {
        margin-bottom: 15px;
    }

}

.JoinIntro {
    @screen md+{
        @apply
            mt-0
            ml-2;
        
        &.shadow-top {
            &::before, &::after {
                @apply  hidden;
            }
        }
    }
    @screen md+ {
        max-width: 95%;
    }
}

.JoinGrowSect{
    @apply
        bg-blue
        py-12
        bg-no-repeat
        bg-right-bottom
        px-[85px]
        text-white;
    background-image: url($dist + '/globe-join.png');
    

    @screen md+ {
            /* background-position: 430px 840px;
            background-size: 40%; */
        background-position: 385px 529px;
        background-size: 100%;
    }

    @screen md{
        @apply  px-0;
    }

    @screen sm {
        @apply  pb-25;
        background-position: center bottom -225px;
        background-size: contain;
    }


    &-header{
        @apply w-7/12 mb-12;



        p{
            @apply  mt-[24px]
                    font-montserrat 
                    font-normal 
                    text-white
                    tracking-normal
                    leading-[27px];
        }

        @screen md+{
            @apply  w-full
                    px-[85px]
                    mr-0
                    mx-auto;

        }

        @screen md{
            @apply  px-0;
        }

        h1{
            @screen md+{
                @apply  w-full
                        mx-auto;
            }
        }

    }


    &-data{
        @apply
            text-center
            flex-col-reverse
            flex
            border-l
            border-gray-600;
        
        dd{
            @apply 
                text-red
                text-60
                pb-5
                font-light
                font-montserrat;
        }

        @screen lg { 
            border-left: none;
            @apply  text-left
                    border-b;
            dd,dt {
                @apply
                    pb-2;
            }
        }

        @screen sm {    
            @apply  w-full;
            
            dd {
                @apply  pb-0;
            }
        }

        //Alternate numbers from blue to red
        &:nth-child(odd){
            @apply border-0;
                @screen lg {
                    @apply
                        border-b;
                }
        }

        &:nth-child(n+2):nth-child(-n+3) dd{
            @apply text-blue-cyan;
        }

    }
}

.gauge-container {
    @apply 
        w-full 
        h-full 
        flex
        items-center
        justify-center;
    background: url($dist + '/gauge.svg') center center / 88% auto no-repeat;

    .gauge{
        margin: -26px -20px;
        max-height: 100%;
        width: 100%;
    }
}
  .gauge-container > .gauge .dial {
    stroke: rgba(0,0,0,0);
    stroke-width: 1;
    stroke-linecap: round;
    fill: rgba(0,0,0,0);
    box-shadow: inset 0px 3px 5px rgba(0, 0, 0, 0.28);
  }
  .gauge-container > .gauge .value {
    stroke: rgb(47, 227, 255);
    stroke-width: 2;
    stroke-linecap: round;
    fill: rgba(0,0,0,0);
  }
  .gauge-container > .gauge .value-text {
    fill: $gray-700;
    @apply font-light font-azo text-21  ;
  }