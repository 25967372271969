.NewsDetail {

    // background-image: url($dist + '/A-graduated-1.png');
    background-size: contain;
    background-position: 50% bottom 50% right;
    @apply  bg-no-repeat;


    &-breadcrumbs {
        @apply  flex 
                items-center
                font-azo
                mt-2;

        li {
            @apply  mr-1
                    text-14
                    font-azo
                    leading-[18px]
                    text-gray-600;

            &:after {
                content: '|';

                @apply  mr-1
                        pl-1;
            }

            &:last-child {
                &:after {
                    content: '';

                    @apply  mr-0
                            pl-0;
                }
            }
        }
    }

    @screen md {

    }

    &-content {
        @apply  table
                mb-4;

        &.prose {
            figure {
                figcaption {
                    @apply  text-left
                            pl-[unset];
                }
            }

            blockquote {
                @apply  font-light;
            }

            h3 {
                @apply  leading-[1.2]
                        mb-2;

                @screen md {
                    @apply  text-[32px];
                }
            }
        }
    }

    &-contentContainer {
        @apply  my-4
                mx-auto
                pb-4
                border-0
                border-b;
                
        border-color: #3D87CB;
    }

    &-relatedArticles {
        .Card {

            filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.5));

            h6.NewsDetail-relatedDate {
                @apply  uppercase 
                        font-azo 
                        font-light 
                        leading-5 
                        text-gray-900
                        flex;

                &:before {
                    content: url($dist + '/pr-icon.svg');
                }
            }
        }
    }
}