label{
    @apply block text-14 text-blue;
}

input[type="checkbox"]{
    @apply
        appearance-none
        border-blue-600
        border-2
        rounded-[3px]
        w-2
        h-2
        my-0
        mr-[15px];

    &:checked{
        @apply 
            bg-blue-bright
            border-blue-bright
            bg-center
            bg-no-repeat;
        background-image: url($dist +'/check.svg');
    }
}


.Row{
    @apply mb-2;

    label{
        @apply 
            block 
            text-14 
            text-blue
            mb-1
            font-azo;
    }

    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="tel"]{
        @apply 
            rounded
            text-14
            p-[15px]
            w-full;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.3);

    }

    input[type="checkbox"]{
        @apply 
            border-none
            shrink-0;
        width: 25px;
        height: 25px;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.3);
    }

    .Agree{
        @apply
            flex 
            items-center;

        label{
            @apply m-0;
        }
    }
}