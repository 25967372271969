.TrainingCopy{
    @apply 
        flex 
        justify-start  
        mt-8
        gap-4;
    
    
    
    &::before {
        content: "";
        @apply 
            border-l-gray-300
            border-t-gray-300
            border-r-white
            border-b-white
            border-solid
            border
            self-stretch;
    }

    &>:first-child{
        order: -1;
    }

    @screen md{
        @apply flex-col;
    }

    > div { 

        a{
            @apply
                inline-block
                border-b
                border-dotted
                border-red
                pb-xs;

        }
        
        figure {
            @apply  mt-3;
        }
    }
}

.Courses {
    .box {
        @apply  px-[45px]
                py-[23px];
    }
}
.CourseList{
    @apply
        font-montserrat
        text-[18px];

    li::before {
        content: "\002022 ";
        @apply
            text-red
            text-45;
        line-height: 19px
    }

    li{

        @apply mb-[14px] flex gap-1 items-start justify-start;
    }

    a{
        @apply
            inline-block
            border-b
            border-dotted
            border-red
            pb-xs;

    }
}


.CareersContentBlock{
    @apply
        flex
        flex-wrap
        justify-start
        text-white;
    gap: calc( 100% / 12 );

    @screen md{
        @apply 
            gap-4 
            flex-col-reverse 
            mt-5;
    }

    p{
        @apply text-white;
    }

    &>div:last-child{
        @apply 
            flex 
            flex-col 
            justify-center;

        @screen md{
            @apply pt-3;
        }
    }

    &-image{
        @apply 
            relative 
            overflow-hidden;
        border-radius: 0 0 90px 0;

        @screen md{
            @apply ml-4 w-auto;
            border-radius: 90px 0 0 90px;
        }

        img{
            @apply h-full w-auto object-cover;
        }

        &::before{
            @apply absolute inset-0 content-[''];
            box-shadow: inset 0px 8px 30px rgba(0, 0, 0, 0.7);
        }
    }

    &:nth-child(even){
        @apply flex-row-reverse;

        @screen md{
            @apply flex-col-reverse mt-5;
        }

        .CareersContentBlock-image{
            border-radius: 90px 0 0 0;

            @screen md{
                @apply mr-4 ml-0;
                border-radius: 0 90px 90px 0;
            }
        }

        &>div:last-child{
            @apply ml-0;

            @screen md{
                @apply mx-auto;
            }
        }
    }
    &-box {
        position:relative;
        top:50px;
        max-width:575px;

        &--button {
            width:265px;
        }

    }
}


.CareersBenefits{
    @apply mt-4;

    li{
        @apply  font-montserrat
                mb-[17px]
                flex 
                gap-x-[15px] 
                items-start 
                justify-start
                big;
        @apply  text-white;
    }

    li::before{
        content: url($dist + '/check-bullet--white.svg');
        @apply -translate-y-sm;
    }
}