.Person{
    @apply pr-3;

    .Card{
        @apply shadow pointer-events-none;

        min-height: 250px;

        &-image{
            @apply transition-all;
        }

        &-body{
            @apply mt-0 space-y-sm pb-5;

        }
    }

    &-position{
        @apply
            uppercase 
            font-medium 
            text-[10px] 
            text-gray-600
            tracking-widest;
    }

    &-bio{
        @apply
            h-0 
            overflow-hidden 
            p-0
            transition-all;

    }

    &.is-selected{
        .Card-image{
            @apply h-0 overflow-hidden flex-grow-0 p-0;
        }
        .Person-bio{
            height: var(--bioHeight);
        }
    }

    &-caret {
        @apply
            absolute
            top-0
            transform
            -translate-y-full;
        left:10%;
        transition: all .5s $easeInOutCubic;
        

        &::before{
            content: inline-svg('<svg width="37" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30.2062 17.4757 23.4467 3.41994c-1.8426-3.685247-7.1016-3.685242-8.9442 0L7.743 17.4757h22.4632Z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="m23.9746 4.47566 3.382 6.27114c1.9618 3.6375 5.5284 6.1328 9.618 6.7289h-13V4.47566ZM13.9746 4.47566l-3.382 6.27114c-1.96174 3.6375-5.52841 6.1328-9.617991 6.7289H13.9746V4.47566Z" fill="#fff"/></svg>');
            @apply  block
                    relative;
            line-height: 0;
            left: 200px;
            border-color: #fff;

            @screen lg+ {
                left: 150px;
            }

            @screen lg {
                left: 120px;
            }

            @screen md+ {
                left: 60px;
            }

            @screen md {
                left: 0px;
            }

        }
    }

}

.PeopleHeadline{
    padding-left: 50px;
    padding-right: 50px;

    @screen md+ {
        text-align: left !important;
    }

    @screen sm {
        text-align: center !important;
    }
}

.PeopleList{
    margin-bottom: 150px;
    padding-left: 50px;
    padding-right: 50px;

    .Card{
        @apply
            flex
            flex-col;

    }

    &-image{
        --tw-aspect-h: 6;
    }

    .Card-body{
        @apply
            flex
            flex-col
            h-full
            min-h-[420px];

            @screen md+ {
                @apply min-h-[330px];
            }

            @screen md {
                @apply min-h-[0px];
            }

    }

    &-partnerIntro{
        @apply
            flex-1;
    }
    &-funFact{

    }
    @screen sm {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.Card-people-body::before {
    content: url(data:image/svg+xml,%3Csvg%20width%3D%2237%22%20height%3D%2217%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M30.2062%2017.4757%2023.4467%203.41994c-1.8426-3.685247-7.1016-3.685242-8.9442%200L7.743%2017.4757h22.4632Z%22%20fill%3D%22%23fff%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22m23.9746%204.47566%203.382%206.27114c1.9618%203.6375%205.5284%206.1328%209.618%206.7289h-13V4.47566ZM13.9746%204.47566l-3.382%206.27114c-1.96174%203.6375-5.52841%206.1328-9.617991%206.7289H13.9746V4.47566Z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    display: block;
    line-height: 0;
    position: relative;
    top: -37px;
}

.Card-years {

    @apply font-azo;

    color: #6D757D;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.86px;
    line-height: 15px;
    text-transform: uppercase;
    margin-top: 3px !important;
    padding-bottom: 15px;
    border-bottom: 1px solid #E5E1E6;
}

.Card-funFactBox {
  padding: 20px;
  background-color: #f7f6f7;
  border-radius: 10px;
}

.Card-funFactHeadline {

  @apply font-azo;

  color: #003052;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.86px;
  line-height: 15px;
}

.Card-funFact {

  @apply font-azo;

  color: #343A40;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
}

.PeopleGallery{
    @apply pb-9 relative;

    .flickity-viewport{
        overflow: visible;
    }

    &-buttons{
        position: absolute;
        top: var(--card-height);
        left: 31%;
        z-index: 50;
        margin-top:80px;

        @screen lg+{
            left: 30%;
            margin-top: 40px;
        }

        @screen lg{
            left: 28%;
        }

        @screen md+{
            left: 43%;
            margin-top: 80px;
        }

        @screen md{
            @apply
                mt-0
                left-auto
                right-[10%];

            top: -315px;

        }
    }
    img {
        // max-height: 545px;
    }
    
}
