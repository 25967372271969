.Footer{
    @apply 
        py-15 
        space-y-10
        relative;
    
    background: $blue url($dist + '/A-graduated-1.png') 150% 100% no-repeat;

    &--white {
        // background: $white url($dist + '/A-graduated-1.png') 150% 100% no-repeat;
        @apply  bg-transparent;

        background-image: none;

        &-A {
            @apply  absolute
                    w-[1100px]
                    h-auto
                    -z-10
                    bottom-0
                    right-0;

            content: url($dist + '/A-graduated-1.png');
        }

        @screen md {
            background: $white;
            background-image: none;
        }
    }

    @screen lg+ {
        // @apply  overflow-hidden;
    }
}

.FooterNav {

    &-container {
        @apply  w-full 
                flex;
    }

    .footer-logo.logo {
        @include size(41px, 43px);
        background: url($dist + '/A.svg');

        @apply  bg-contain;
    }

    .NewsletterForm {
        @screen md {
            @apply  flex
                    justify-between;

            button {
                order: 1;
            }

            input[type=email] {
                order: 2;
            }
        }
    }
}

.QuickLinks {
    @apply  border-0 
            border-l
            pl-3
            font-azo
            border-[#C2D1D9];

    @screen md {
        @apply  pl-0
                border-l-0
                my-3;
    }

    &-links {
        @apply  mt-1 
                grid 
                col-auto 
                grid-flow-col 
                grid-rows-5
                auto-rows-min 
                gap-y-1
                gap-x-4;

        li {
            @apply  h-[18px];
            a {
                @apply  text-gray-600
                        text-14;

                &:hover {
                    @apply  text-blue-dark
                            border-b  
                            border-red
                            ;
                }
            }
        }

        @screen md {
            @apply  row-auto
                    grid-flow-row;
        }

        @screen md {
            @apply  grid-flow-row
                    auto-rows-auto;
        }
    }
}

.FooterLegal {

    @apply  flex
            items-center;

    li {
        @apply  flex
                items-center;
                
        &::before {
            @apply  content-['|']
                    ml-1
                    mr-1;

        }

        a {
            @apply  text-white
                    text-12
                    leading-[26px]
                    font-light;
        }

        &:first-of-type {
            &::before {
                @apply  content-[''];
            }
        }
    }

    @screen md {

        li {
            &:before {
                @apply  hidden;
            }
        }
    }
}