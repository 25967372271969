.icon {
    &-facebook {
        &::before {
            content: url($dist + '/facebook.svg');
        }
    }
    &-linkedin {
        &::before {
            content: url($dist + '/linkedin.svg');
        }
    }
    &-twitter {
        &::before {
            content: url($dist + '/twitter.svg');
        }
    }
    &-email {
        &::before {
            content: url($dist + '/blog-email.svg');
        }
    }
}