// --------------------------------------------------------------
// Fullscreen
// --------------------------------------------------------------

.plyr:fullscreen {
  @include plyr-fullscreen-active;
}

// Fallback for unsupported browsers
.plyr--fullscreen-fallback {
  @include plyr-fullscreen-active;

  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000;
}
