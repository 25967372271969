.ValuesHero{

    @apply
        pt-12
        pb-25
        relative
        overflow-visible;

    @screen md{
        @apply pb-15;

    }

    @screen sm{
        @apply py-5;

    }

    img{
        @apply 
            rounded
            shadow;
    }


    &-images{

        @screen md+{
            overflow-x: hidden;
        }

        @apply absolute inset-0;

        img{
            @apply object-cover absolute;
            @screen sm{
                @apply hidden;
            }
        }

        img:not(:first-child){
            @apply z-10;

            
        }

        img:nth-child(3){
            right: calc(var(--container) + (100vw - var(--container))/2);

            @screen md{
                right: calc(var(--container) + (100vw - var(--container))/1.5); 
            }
        }
        img:nth-child(5){
            left: calc((100vw - var(--container))/2);
        }

        @screen lg{
            img{ @apply scale-90 }
        }

        &::after{
            content: url($dist + '/AGraduated-color--inverse.png');
            @apply
                absolute
                bottom-0
                left-0
                -translate-x-1/4
                hidden;
            z-index: 0;
        }

        @screen md{
            @apply block
        }
    }
}

.ValuesIntro{
    @apply relative;

    &::before{
        content: url($dist + '/AGraduated-color.png');
        filter: brightness(140%);
        @apply
            absolute
            -top-30
            -left-10;
        
        @screen md+{
            @apply hidden;
        }
    }
}

.Values{
    @apply
        bg-white
        rounded-lg
        shadow
        overflow-hidden;

    &-item{
        @apply
            border-b
            border-gray-300
            relative
            pb-0
            transition-all;
    }
    
    &-title{
        @apply
            block
            px-5
            py-2
            relative
            z-10;
        padding-right: 37%;
        background: url($dist + '/plus.svg') right 26px top 24px no-repeat;

        @screen lg{
            @apply px-3
        }
        
    }

    &-body{
        @apply 
            px-5 
            w-1/2;
        
        @screen lg{
            @apply px-3 w-8/12;
        }
        @screen md{
            @apply w-full;
        }
    }

    &-image{
        @apply
            absolute
            w-1/3
            top-0
            right-0
            bottom-0;

        img{
            @apply
                w-full
                h-full
                object-cover;
        }

        @screen md{
            @apply static w-full pt-1;
        }
    }

    &-item.is-open{
        @apply pb-3;

        @screen md{
            @apply p-0;
        }

        .Values-title{
            background: url($dist + '/minus.svg') right 19px top 18px no-repeat;
        }

        
    }
}
.ValueBlockTitles {
    @screen md {
        font-size: 18px;
        font-weight: 300;
        letter-spacing: -0.71px;
        line-height: 26px;
    }
    @screen sm {
        padding-right: 60px;
    }
}