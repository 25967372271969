.PartnerStat{
    width: 248px;

    @screen md+ {
        width: 183px;
    }

    .StatBox{
        @apply  shadow-statBox 
                w-full
                pb-0;

        &-label {
            @apply  min-h-[70px]
                    pb-2;
        }


    }
}

.PartnerExpertise{
    @apply w-3/12 p-3;
}


.RelatedPerson{
    @apply text-14;

    @screen lg{
        @apply relative;

        img{
            @apply w-7/12;
        }
    }

    @screen md{
        img{
            @apply w-full;
        }
    }

    &-title{
        @apply
            text-12
            uppercase
            text-red
            tracking-wider
            font-medium
            mb-1;
    }

    &-info{
        @apply
            shadow 
            -mt-3
            flex-1;
        @extend .box;
        @include caret('top');

        @screen lg{
            @apply 
                mt-0 
                -ml-3 
                w-5/12 
                absolute 
                right-0 
                top-1/2 
                transform 
                -translate-y-1/2;
            @include caret('left');
            position: absolute !important;
        }

        @screen md{
            @include caret('top');
            position: relative !important;
            @apply
                translate-y-0
                w-11/12
                mx-auto
                -mt-2;

        }
    }
}

.PartnerStatsTitle {
    line-height: 43px;
}

.MapInfo{
    @apply
        shadow 
        w-4/12 
        absolute 
        right-[8.3%] 
        top-0 
        bottom-0;

    @screen md{
        @apply 
            relative
            right-0
            w-full;
        @include caret(top);
    }
}

.ModalOverlay {
    position: fixed;
}

.PartnerForm {


    .fui-i {
        @apply  w-full
                ml-auto;

        @screen lg {
            @apply  mr-auto
                    ml-0
                    mt-7
                    w-full;
        }
    }

    &-form {
        .fui-form-container {
            @apply  pb-4;
        }

        .fui-field-container {
            .fui-label {
                @apply  mb-1;
            }

            .fui-input-container {
                input[type=email], 
                input[type=text],
                input[type=tel],
                select,
                textarea,
                .fui-input {
                    @apply  rounded
                            w-full
                            pt-1 pr-1 pb-1 pl-1
                            text-14
                            leading-6
                            text-gray-700
                            font-azo;
                    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.3);
                }

                input[type=tel] {
                    @apply  pl-[52px];
                }

                select {
                    @apply  appearance-none;

                    background: url($dist + '/down-arrow--select.svg') no-repeat;
                    background-position: right $spacing-1 center;

                    
                }
            }
        }

        .fui-form-container {
            .fui-btn-container {
                @apply  mx-0
                        float-left;

                .fui-submit {
                    @extend  .btn;
                    @apply  border-0
                            mx-auto
                            pl-[55px]
                            pr-[55px];
                }
            }
        }
    }
}