.ListingHero {
    &-header {
        &--resources {
            @apply  flex
                    pt-10
                    pb-12;

            background-position: right 20% top 55%;
        }
    }
}

.ResourcesListings {

    .Card {
        @apply  col-span-1
                row-span-1
                h-25;

        box-shadow: 0 4px 7px 0 rgb(0, 0, 0, 30%);

        h5, h6 {
            @apply  w-3/5;

            @screen md {
                @apply  w-full;
            }
        }

        &.ResourcesListings-featured {
            @apply  col-span-2
                    row-span-2
                    h-full
                    flex
                    p-0 
                    rounded-lg;

            
            @screen md {
                @apply  col-span-1
                        flex-col;

            }
        }

        &-body {
            .ResourcesListings-download {
                @apply  self-start 
                        justify-self-end 
                        mt-auto
                        font-azo
                        text-12
                        font-medium
                        leading-[15px];

                letter-spacing: 0.86px;

                @screen md {
                    @apply  mt-3;
                }
            }
        }
    }

    &-featured {
        &--image {
            @apply  w-1/3 
                    overflow-hidden 
                    min-h-full;

            img {
                @apply  h-full 
                        w-auto 
                        object-cover;
            }
        }

        @screen md {
            &--image {
                @apply  w-full 
                        h-full
                        min-h-[160px]
                        max-h-[160px];

                img {
                    @apply  w-full;
                }
            }
        }
    }

    .fui-alert {
        @apply  hidden;
    }
}

.DownloadModal {
    &-input {
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.3);
    }
    &-body {
        label {
             color: #003052;
             font-size: 14px;
             letter-spacing: 0;
             font-weight: 400;
             line-height: 18px;
        }
        > .fui-field {
            padding-left: 0px;
        }
    }
}

.DownloadModalForm {
    .fui-form-container {
        @apply  pl-3 
                pr-10 
                pb-4;

        label {
            color: #003052;
            font-size: 14px;
            letter-spacing: 0;
            font-weight: 400;
            line-height: 18px;
       }
       > .fui-field {
           padding-left: 0px;
       }
    }

    &-input {
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.3);
    }

    button[type=submit].fui-btn {
        @extend  .btn;

        @apply  px-6
                py-1
                mt-1;
    }
}