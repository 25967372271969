.Hero{
    @apply
        bg-blue
        text-white
        flex
        bg-cover
        bg-no-repeat
        bg-center
        overflow-hidden
        relative;
    min-height: 585px;

    background-image: var(--img);

    @screen retina{
        background-image: var(--retina);
    }

    @screen md {
        background-image: none;
    }


    &-mask{
        
        mask: url('#{$dist}/A.svg');
        // -webkit-mask-composite:destination-out;
        mask-composite:exclude;
        // -webkit-mask-repeat:no-repeat;

        @apply  bg-fixed 
                absolute 
                inset-0;
        
        background-position: calc(100vw / (12 / 5)) 0%;
        filter:drop-shadow(0 0 10px black);

        @screen md{
            mask: none;
        }
    }


    &--overlay{
        &::before{
            content: '';

            @apply  absolute
                    z-0
                    w-full
                    -inset-y-full
                    bg-blue
                    bg-opacity-70;

            clip-path: polygon(0 0, 85% 0, 56% 100%, 0% 100%);
            // left: -10%;
        }
        &::after{
            content: '';
            @apply  absolute
                    inset-0
                    w-full
                    bg-no-repeat
                    bg-center;
            background-image: url($dist + '/hero-a.png');
            background-size: auto 100%;

            @screen retina{
                background-image: url($dist + '/hero-a@2x.png');
            }
        }

        h1{
            @apply relative z-10;
        }

        &-half {
            &::before {
                // clip-path: polygon(0 0, 50% 0, 35% 100%, 0% 100%);
                transform: translateX(-28%);
            }

            h1.full-width {
                @apply  w-2/5;
            }
        }

        @screen md+{
            @apply  pb-[160px];
            


            &-container {
                @apply  absolute
                        w-full
                        h-max
                        bg-blue
                        bottom-0
                        left-0
                        z-[60];

                max-width: 100% !important;

                h1.full-width {
                    @apply  pl-[28px]
                            py-3
                            max-w-none
                            mx-auto
                            w-full;
                }
            }
        }
        @screen md{
            @apply  relative;
            background-image: var(--img);


        }

        @screen retina{
            background-image: var(--retina) !important;
        }

        @screen sm{
            h1{
                @apply  absolute
                        inset-x-0
                        bg-blue
                        p-3
                        w-full
                        bottom-0;

            }
        }
    }


    .ShadowMask {
        @apply  relative
                w-full
                overflow-hidden
                h-[585px]
                bg-no-repeat;

        background-position: 37vw 71px;
        background-size: 1030px;
        background-attachment: fixed;

        &-shadow {
            @apply  absolute
                    top-1/2
                    -translate-y-1/2
                    left-0
                    h-[1080px]
                    w-full
                    -mt-7;

            filter: drop-shadow(14px 14px 10px rgba(0,0,0,0.9));

            &::before {
                @apply  absolute
                        top-0
                        left-0
                        h-[1240px]
                        w-full
                        bg-blue;

                content: ' ';
                mask: url('#{$dist}/A-Home.png') center/contain no-repeat, linear-gradient(#fff 0 0);
                mask-composite: exclude;

                @screen xl {
                    transform: scale(1.2);
                    top: -7px;
                    left: 59px;
                }
                @screen lg+ {
                    top: -1.3rem;
                    left: 3.4rem;
                    transform: scale(1.22);
                }
                @screen lg {
                    top: -1.5rem;
                    left: 4.5rem;
                    transform: scale(1.3);
                }
                @screen md+ {
                    top: -5.5rem;
                    left: 7rem;
                    transform: scale(1.65);
                }
            }
        }
        &-shadow-about {
            &::before {
                @apply  h-[1170px];
            }
        }

        @screen lg+ {

            background-position: 0vw 96px;
            background-size: 618px;
            background-attachment: scroll;

            @apply  scale-150
                    translate-x-1/2;
        }

        @screen  md+ {
            &-shadow {
                top: 62%;

                @apply  h-[1030px];
            }
        }

        @screen md {

            background-position: 0vw 0px;

            @apply  bg-cover
                    bg-no-repeat
                    translate-x-0
                    min-h-[200px]
                    max-h-[260px]
                    h-full
                    scale-100
                    relative;

            width: 100% !important;

            &::after {
                @apply  absolute
                        bottom-0
                        w-full
                        h-[26px]
                        opacity-70
                        bg-gradient-to-b
                        from-transparent
                        to-black;

                content: ' ';
            }

            &-shadow {
                @apply  relative
                        top-[unset];

                &::before {
                    @apply  hidden;
                }
            }
        }
        &.AboutCutoutBackground {
            background-size: 1115px;
            transform: scale(1.2);
            background-position: 44vw 46px;
            background-repeat: repeat;

            @screen lg+ {
                transform: scale(1.5);
            }
            @screen md {
                transform: scale(1);
                background-position: 0vw 0px;
            }

        }
    }

    @screen md {
        > .container {
            max-width: unset;
            justify-content: flex-start;

            > h1 {
                @apply  px-3;
            }
        }
    }
}

.Hero--overlay {
    &--solutionEntry {
        &::before{
            clip-path: polygon(0 0, 91% 0, 64% 78%, 0% 100%);
        }
    }
    &--service {
        &::before{
            clip-path: polygon(0 0, 63% 0, 24% 100%, 0% 100%);
        }
    }
    &--serviceEntry {
        &::before{
            clip-path: polygon(0 0, 92% 0, 54% 100%, 0% 100%);
        }
    }
    &--market {
        &::before{
            clip-path: polygon(0 0, 54% 0, 42% 70%, 0% 100%);
        }
        &::after{
            background-size: auto 175%;
        }
    }
    &--marketEntry {
        &::before{
            clip-path: polygon(0 0, 57% 0, 45% 70%, 0% 100%);
        }
        &::after{
            background-size: auto 150%;
        }
    }
    &--careers {
        &::before{
            clip-path: polygon(0 0, 63% 0, 33% 100%, 0% 100%);
        }
    }
    &--partnerOverview {
        &::before{
            clip-path: polygon(0 0, 91% 0, 60% 100%, 0% 100%);
        }
    }
    &--partner {
        &::before{
            clip-path: polygon(0 0, 88% 0, 60% 100%, 0% 100%);
        }
    }
    &--join {
        &::before{
            clip-path: polygon(0 0, 62% 0, 32% 100%, 0% 100%);
        }
    }
    &--blogDetail {
        &::before{
            clip-path: polygon(0 0, 62% 0, 32% 100%, 0% 100%);
            z-index: 1;
        }
        &::after{
            --tw-scale-x: 150%;
            --tw-scale-y: 150%;
        }
    }
    &--partner {
        &::before{
            clip-path: polygon(0 0, 70% 0, 49% 100%, 0% 100%);

            @screen md+ {
                clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
            }

        }
        &::after{
            background-image: none;
        }
    }
}

.ListingHero {
    &-header {
        @apply  bg-no-repeat
                pt-7;
        background-image: url($dist + '/blog-news-header.svg');
        background-color: $blue;
    }

    &-headerTitle {
        @apply  text-54 
                font-montserrat 
                font-light 
                leading-[66px] 
                text-white 
                pb-10;

        letter-spacing: -1px;

        @screen md {
            @apply  text-[42px];
        }
    }

    &-articles {
        @apply  grid
                grid-cols-3
                gap-2;
        grid-template-rows: 30rem 23.75rem;


        @screen md {
            @apply  flex
                    flex-col;
        }
    }

    &-featured, &-article {
        @apply  bg-cover
                bg-no-repeat
                rounded-lg
                flex
                justify-start
                items-end
                shadow-sm;


        @screen md {
            @apply  w-full
                    h-40;
        }
    }

    &-text {
        @apply  bg-blue-dark
                bg-opacity-75
                rounded
                px-2
                py-3
                text-white
                h-auto
                transition-transform
                flex
                flex-col;

        p {
            @apply  text-white;
        }

        .ListingHero-more {
            @apply  flex 
                    flex-col 
                    items-start 
                    justify-between
                    transition-all
                    ease-in-out
                    opacity-0
                    h-0
                    pointer-events-none;
        }
        
        &.is-active {
            @apply  h-full;

            .ListingHero-more {
                @apply  h-full
                        opacity-100
                        pointer-events-auto;
            }
        }
    }

    &-featured {
        @apply  row-span-1
                col-span-3
                p-1;


        h6 {
            @apply  text-28;
        }

        > .ListingHero-text {
            @apply  w-8/12;

            &.is-active {
                @apply  h-2/3;
            }

            @screen md {
                @apply  w-full;
            }
        }
    }

    &-article {
        @apply  col-auto
                row-span-1
                p-1;
        h6 {
            @apply  text-21
                    leading-6
                    min-h-[50px]
                    text-white;
        }
    }
}
