@layer utilities {
    .heading-jb-1 {
        line-height: 51px;
        @apply  text-gray-700
                font-montserrat
                text-41
                font-light
                h-unset
                leading-[51px]
                tracking-[-0.4px];
    }
    .heading-jb-2 {
        line-height: 50px;
        @apply  text-gray-700
                font-montserrat
                text-38
                font-light
                h-unset
                leading-[50px]
                tracking-[-0.3px];
                
        // letter-spacing: -0.3px;
    }
    .heading-jb-3 {
        line-height: 42px;
        @apply  text-gray-700
                font-montserrat
                text-34
                font-light
                tracking-[-0.1px]
                leading-[42px]
                h-unset;
    }
    .heading-jb-4 {
        line-height: 34px;
        @apply  text-gray-700
                font-montserrat
                text-28
                font-light
                tracking-normal
                leading-[34px]
                h-unset;
    }
    .heading-jb-5 {
        line-height: 32px;
        @apply  text-gray-700
                font-montserrat
                text-26
                font-regular
                tracking-normal
                leading-8
                h-unset;
    }
    .heading-jb-6 {
        line-height: 25px;
        @apply  text-gray-700
                font-montserrat
                text-21
                font-regular
                tracking-normal
                leading-[25px]
                h-unset;
    }
    .display-jb-1 {
        line-height: 84px;
        @apply  text-gray-700
                font-montserrat
                text-82
                font-thin
                tracking-[-4px]
                leading-[84px]
                h-unset;
    }
    .display-jb-2 {
        line-height: 68px;
        @apply  text-gray-700
                font-montserrat
                text-60
                font-light
                tracking-[-1px]
                leading-[68px]
                h-unset;
                
    }
    .display-jb-3 {
        line-height: 66px;
        @apply  text-gray-700
                font-montserrat
                text-54
                font-light
                tracking-[-1px]
                leading-[66px]
                h-unset;
                
    }
    .display-jb-4 {
        line-height: 58px;
        @apply  text-gray-700
                font-montserrat
                text-50
                font-light
                tracking-normal
                leading-[58px]
                h-unset;
    }
    .display-jb-5 {
        line-height: 55px;
        @apply  text-gray-700
                font-montserrat
                font-light
                text-45
                tracking-normal
                h-unset;
    }

    .big {
        line-height: 27px;
        @apply  text-gray-800
                font-montserrat
                text-20
                font-regular
                tracking-normal
                leading-[27px]
                h-unset;
    }

    .small {
        line-height: 20px;
        @apply  text-gray-800
                font-azo
                text-14
                font-light
                tracking-[0.2px]
                leading-5
                h-unset;
    }

    .smaller {
        @apply  text-12
                leading-[26px];
    }

    .regular {
        @apply  text-gray-800
                font-azo
                text-16
                font-light
                tracking-normal
                leading-6
                h-unset;
    }

    .small-icon {
        @apply  w-[21px];
    }

    .shadow-top {
        @apply  relative;
    
        &>*{
            @apply relative z-20;
        }
    
        &::before {
            @apply  absolute
                    w-full
                    h-20
                    px-7
                    left-0
                    top-0
                    bg-white
                    rounded-t-lg;
    
            z-index: 11;
            content: ' ';
            
            filter: drop-shadow(0 0 24px rgba(0, 0, 0, 0.35));
    
        }
    
        &::after {
            @apply  absolute
                    left-0
                    top-0
                    h-25
                    w-full
                    px-7
                    bg-gradient-to-t 
                    via-white
                    from-white;
            z-index: 12;
            content: ' ';
                    
        }
    
        @screen md{
            &::before,
            &::after{
                @apply hidden;
            }
        }
    }
    .text-white {
        color: #fff !important;
    }
    .text-red {
        color: #c30000 !important;
    }
    .bg-blur {
        background-color: rgba(66,85,99,0.5);
        backdrop-filter: blur(4px);
    }
}