.BlogDetail {
    @apply 
        relative 
        bg-white 
        rounded-t-lg
        rounded-b-none
        -mt-8 
        items-center 
        z-10;
    

    @screen md {
        @apply  mt-2;

        &.shadow-top {
            &::before, &::after {
                @apply  hidden;
            }
        }
    }

    &-leadImage {
        @apply  min-h-[350px]
                max-h-[350px]
                z-0 
                overflow-hidden 
                relative;
    }

    &-header {
        @apply  relative
                flex
                flex-wrap
                items-center
                -top-2
                px-7
                z-20;
    
        @screen md {
            @apply  px-1
                    top-0
                    mt-0;
        }
    }
}

.BlogDetail, .NewsDetail {
    &-content.prose {

        @apply  relative
                px-7
                z-30;

        @screen md {
            @apply  px-1
                    mt-2;
        }
        
        h1, h2, h3, h4, h5, h6, p {
            @apply  px-7;
        }

        @screen md {
            h1, h2, h3, h4, h5, h6, p {
                @apply  px-0;
            }
        }

        blockquote {
            @apply  grid
                    font-azo
                    text-16
                    font-normal
                    leading-6
                    mt-5
                    mb-7
                    pr-6;

            grid-template-columns: 115px 1fr;

            &:before {
                // background-image: url($dist + '/double-quote.svg');
                // background-position: top left;
                content: url($dist + '/double-quote.svg');
                width: 80px;
                height: auto;
                padding-right: 35px;

                @apply  border-0
                        border-r
                        border-blue-sky;

                
            }
        }
        
        p {
            @apply  text-16
                    leading-6
                    font-azo
                    mb-3;

            &:first-of-type {
                @apply  text-20
                        leading-7
                        font-montserrat;
            }
        }
        
        figure {
            img {
                @apply  rounded-lg;
            }

            figcaption {
                @apply  w-full
                        text-center
                        px-7
                        text-14
                        font-light
                        italic
                        mt-1;

                color: #2E3092;
            }
        }

        p + figure {
            @apply  my-5;
        }

        .floated {
            &-left {
                clear:left;
                width: 50%;
                padding-left: 0;
                padding-right: 100px;
            }

            &-right {
                width: 50%;
                padding-right: 0;
                padding-left: 100px;
            }

            @screen md {
                &-left, &-right {
                    width: 100%;
                    clear:both;
                    padding-left: 0;
                    padding-rght: 0;
                }
            }
        }

        p + h2.floated-left, p + h2.floated-right {
            margin-top: 50px;
            width: 100%;
        }

        figure.image {
            &-right {
                @apply  float-right
                        w-1/2
                        mt-0
                        mb-5;
            }

            &-left {
                @apply  float-left
                        w-1/2
                        mt-0
                        mb-5;
            }

            @screen md {
                &-left, &-right {
                    @apply  float-none
                            w-full;
                }
            }
        }

        &::after, figure.image-right + p::before {
            @apply  float-none
                    block;
            content: ' ';
            clear: both;
        }

        a {
            @apply text-blue-cyan;

            text-decoration:underline;
        }

        a:hover {
            @apply text-red;
        }
    }
}

.BlogDetail-content.prose {
    ul {
        @apply  mb-3
                font-azo
                text-16
                leading-6
                text-gray-800
                font-light
                px-[6.375rem];

        li {
            @apply  mb-[0.875rem]
                    font-azo
                    text-16
                    leading-6
                    text-gray-800
                    font-light;
        }
    }
}

.NewsDetail-content.prose {
    @apply  px-0;


    h1, h2, h3, h4, h5, h6, p {
        @apply  px-0;
    }

    @screen md {
        h1, h2, h3, h4, h5, h6, p {
            @apply  px-0;
        }
    }
}

.SocialList {

    li {
        @apply  mr-1;

        &:last-child {
            @apply  mr-0;
        }
    }
}