.SolutionIntro{
    @apply
        flex 
        justify-end
        flex-wrap
        gap-y-3
        bg-white 
        rounded-lg 
        -mt-6
        pt-5;

    @screen md{
        @apply flex-col px-3;
    }

    @screen md+{
        @apply
            mt-0;
        
        &.shadow-top {
            &::before, &::after {
                @apply  hidden;
            }
        }
    }

    &-copy {
        p {
            @apply  mb-2
                    pr-4
                    regular;

            @screen md {
                @apply  pr-0;
            }
        }
    }
}

.SolutionContentBlock{
    @apply
        flex
        flex-wrap
        justify-between;
    gap: calc( 100% / 12 );

    @screen md{
        @apply 
            gap-4 
            flex-col-reverse 
            mt-5;
    }

    &>div:first-child{
        @apply 
            ml-auto 
            flex 
            flex-col 
            justify-center 
            pt-2;

        @screen md{
            @apply pt-3;
        }
    }

    &-image{
        @apply 
            relative 
            overflow-hidden;
        border-radius: 0 0 0 90px;

        @screen md{
            @apply ml-4 w-auto;
            border-radius: 60px 0 0 60px;
        }

        img{
            @apply h-full w-auto object-cover;
        }
    
        &::before{
            @apply absolute inset-0 content-[''];
            box-shadow: inset 0px 8px 30px rgba(0, 0, 0, 0.7);
        }
    }

    &:nth-child(even){
        @apply flex-row-reverse;

        @screen md{
            @apply flex-col-reverse mt-5;
        }

        .SolutionContentBlock-image{
            border-radius: 0 90px 0 0;

            @screen md{
                @apply mr-4 ml-0;
                border-radius: 0 60px 60px 0;
            }
        }

        &>div:first-child{
            @apply mr-auto ml-0;

            @screen md{
                @apply mx-auto;
            }
        }
    }
}

.RelatedSolutions{
    @apply text-white;

    &-carousel{
        @apply  mt-5
                h-[515px];
    }

    .flickity-viewport{
        @apply 
            rounded-lg
            shadow;
    }

    .flickity-prev-next-button {
        display: none;
        @screen lg {
            display: block;
        }
    }

    &-item{
        @apply  bg-cover
                bg-center
                h-[515px];
        background-image: var(--img);

        @screen retina{
            background-image: var(--retina);
        }
    }

    &-info{
        @apply 
            absolute
            bottom-0
            top-auto
            left-0
            w-3/4
            bg-blue
            bg-opacity-80
            rounded-tr
            py-4
            px-5;

        @screen sm {
            @apply 
                w-full;
        }
    }

    .flickity-prev-next-button{
        @apply 
            absolute
            -top-1;
    }

    .previous{
        @apply right-7 left-auto;
    }
}