$dist: '/assets/dist/images';
$rem-baseline: 20px;
$max-width: 1480px;


// Colors
$black:#000000;
$white:#ffffff;
$red: #c30000;
$red-bright: #ff2f2f;
$blue: #003052;
$blue-dark: #003052;
$blue-cyan: #02a2e0;
$blue-mid: #33667e;
$blue-600: #688398;
$blue-light: #b9d3dc;
$blue-bright: #2d3091;
$blue-sky: #3e87ca;
$gray: #e5e1e6;
$gray-light: #e5e1e6;
$gray-100: #F8F9FA;
$gray-200: #E8ECEF;
$gray-300: #DEE2E5;
$gray-400: #CED4DA;
$gray-500: #ADB5BD;
$gray-600: #6D757D;
$gray-700: #495057;
$gray-800: #343A40;
$gray-900: #22252A;

//Screens
$xl: 	    1440px;
$lg-plus: 	1280px;
$lg: 	    1024px;
$md-plus: 	980px;
$md: 	    768px;
$sm: 	    480px;


//As CSS Variables
:root {
    --xl: 	    1440px;
    --lg: 	    1024px;
    --md-plus: 	980px;
    --md: 	    768px;
    --sm: 	    480px;

    --black:#{$black};
    --white:#{$white};
}

//Spacing
$spacing-0: 0rem;
$spacing-xs: 0.15625rem; // 2.5px
$spacing-sm: 0.3125rem;   // 5px  
$spacing-1: 0.625rem;  	// 10px 
$spacing-2: 1.25rem;   	// 20px 
$spacing-3: 1.875rem;  	// 30px 
$spacing-4: 2.5rem;    	// 40px 
$spacing-5: 3.125rem;  	// 50px 
$spacing-6: 3.75rem;   	// 60px 
$spacing-7: 4.375rem;  	// 70px 
$spacing-8: 5rem;      	// 80px 
$spacing-9: 5.625rem;  	// 90px 
$spacing-10: 6.25rem;   	// 100px
$spacing-12: 7.5rem;    	// 120px
$spacing-15: 9.375rem;  	// 150px
$spacing-20: 12.5rem;   	// 200px
$spacing-25: 15.625rem; 	// 250px
$spacing-30: 18.75rem;  	// 300px
$spacing-40: 25rem;     	// 400px
$spacing-50: 31.25rem;  	// 500px
$spacing-100: 62.5rem;	// 1000px


//easing functions
$linear         : cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease           : cubic-bezier(0.250, 0.100, 0.250, 1.000);
$ease-in        : cubic-bezier(0.420, 0.000, 1.000, 1.000);
$ease-out       : cubic-bezier(0.000, 0.000, 0.580, 1.000);
$ease-in-out    : cubic-bezier(0.420, 0.000, 0.580, 1.000);

$easeInQuad     : cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic    : cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart    : cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint    : cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine     : cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo     : cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc     : cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack     : cubic-bezier(0.600, -0.280, 0.735, 0.045);

$easeOutQuad    : cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic   : cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart   : cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint   : cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine    : cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo    : cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc    : cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack    : cubic-bezier(0.175, 0.885, 0.320, 1.275);

$easeInOutQuad  : cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic : cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart : cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint : cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine  : cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo  : cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc  : cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack  : cubic-bezier(0.680, -0.550, 0.265, 1.550);