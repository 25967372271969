.Market{
    @apply
        block
        relative
        w-5/12
        overflow-hidden
        transition-all;
    
    @screen lg{
        @apply w-full;
    }

    &-copy{
        @apply  absolute
                bottom-0
                left-0
                z-10
                text-white
                bg-blue
                bg-opacity-80
                py-3
                px-4
                w-9/12
                rounded-tr-[11px];

        @screen lg{
            @apply w-full;
        }

        
        @screen md{
            @apply  static 
                    w-full 
                    bg-opacity-100
                    rounded-none
                    border-0
                    border-b-2
                    border-white;
        }

        p{
            @apply font-light leading-snug;

            @screen md{
                @apply line-clamp-4;
            }
        }
    }
    
    //Rounding Corners & Hovering
    &:first-child{
        @apply rounded-tl-lg;
    }
    &:nth-child(2){
        @apply rounded-tr-lg;
    }

    &:last-child{
        @apply rounded-br-lg;

        .Market-copy {
            @apply  border-b-0;
        }
    }
    &:nth-last-child(2){
        @apply rounded-bl-lg;
    }

    @screen lg{
        &:first-child{
            @apply rounded-t-lg;
        }
        &:last-child{
            @apply rounded-b-lg;
        }
        &:nth-child(2){
            @apply rounded-none;
        }
        &:nth-last-child(2){
            @apply rounded-none;
        }
    }

    &:hover{
        @apply  transform
                scale-105
                z-20;
                // rounded-none
        @screen md{
            @apply scale-100 transform-none rounded-none;
        }
    }
}

.MarketDetails {
    &-nav {
        @apply  bg-white 
                rounded-lg 
                py-[27px] 
                px-[31px];

        box-shadow: 0 4px 7px 0 rgba(0,0,0,0.3);

        h6 {
            @apply  heading-jb-5
                    pb-[8px]
                    border-b 
                    border-blue 
                    border-opacity-20;
        }

        &--btn {
            @apply  border-blue-bright 
                    border 
                    bg-white 
                    flex 
                    justify-between
                    py-[7px]
                    px-[22px];

            &:after{
                content: url($dist + '/arrow-right.svg');
                @apply  ml-1 
                        w-[38px] 
                        h-[8px]
                        inline-block;
                transition: all .5s ease;
                // transform: translateX(0px);
            }

            &:hover {
                @apply  bg-blue-bright;

                &:after {
                    content: url($dist + '/right-arrow--white.svg');
                }

                span {
                    @apply  text-white;
                }
            }
        }
    }

    &-content {

        p {
            @apply  mb-2;
        }
        a {
            @apply text-blue-cyan;

            text-decoration:underline;
        }

        a:hover {
            @apply text-red;
        }

        .btn {
            @apply text-white;
            text-decoration: none;
        }

        .btn:hover {
            @apply text-white;
        }
    }
}

.MarketsGrowing {
    .JoinGrowing {
        h1 {
            @apply  text-white;
        }

        @screen md+ {
            @apply  pt-0;
        }
    }
}

.MarketIntro {
    @screen md+{
        @apply
            mt-0;
        
        &.shadow-top {
            &::before, &::after {
                @apply  hidden;
            }
        }
    }
}