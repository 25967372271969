.Solution{
    @apply
        block
        bg-no-repeat
        bg-cover
        bg-fixed
        bg-center
        h-screen
        flex;
    background-image: var(--img);

    @screen retina{
        background-image: var(--retina);
    }

    @screen sm{
        @apply bg-scroll;
        height: 75vh;

        .box{
            
        }
    }

    &-box {
        @apply  w-5/12 
                ml-[8%] 
                py-[44px]
                px-[47px]
                bg-white
                rounded
                shadow-xs;

        @screen lg {
            @apply  w-6/12;
        }

        @screen md {
            @apply  w-9/12
                    ml-0;
        }

        @screen sm {
            @apply  w-full
                    translate-y-1/2
                    z-40;
        }

        strong {
            @apply  uppercase 
                    text-red 
                    text-12 
                    font-montserrat 
                    font-medium 
                    tracking-[0.86px] 
                    leading-[15px]
                    mb-[6px];
        }

        h4 {
            @apply  heading-jb-1
                    font-normal
                    w-[55%];
        }

        p {
    
            @apply  text-gray-800
                    font-azo
                    text-16
                    font-light
                    tracking-normal
                    leading-6;
        }
    }

}
.solutionsOverview {
    .Footer {
        @screen sm {
            @apply pt-30;
        }
    }
}
/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .Solution {
        background-attachment: scroll;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .Solution {
        background-attachment: scroll;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .Solution {
        background-attachment: scroll;
    }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .Solution {
        background-attachment: scroll;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .Solution {
        background-attachment: scroll;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .Solution {
        background-attachment: scroll;
    }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 1112px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .Solution {
        background-attachment: scroll;
    }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .Solution {
        background-attachment: scroll;
    }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1112px) 
  and (max-device-width: 1112px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .Solution {
        background-attachment: scroll;
    }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .Solution {
        background-attachment: scroll;
    }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .Solution {
        background-attachment: scroll;
    }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .Solution {
        background-attachment: scroll;
    }
}