.box{
    @apply
        bg-white
        rounded
        px-2
        py-3;
}


.caret{
    
    &-t,
    &-top,
    &-r,
    &-right,
    &-b,
    &-bottom,
    &-l,
    &-left{
        position: relative;
        
        &::before{
            content: inline-svg('<svg width="37" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30.2062 17.4757 23.4467 3.41994c-1.8426-3.685247-7.1016-3.685242-8.9442 0L7.743 17.4757h22.4632Z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="m23.9746 4.47566 3.382 6.27114c1.9618 3.6375 5.5284 6.1328 9.618 6.7289h-13V4.47566ZM13.9746 4.47566l-3.382 6.27114c-1.96174 3.6375-5.52841 6.1328-9.617991 6.7289H13.9746V4.47566Z" fill="#fff"/></svg>');
            @apply 
                absolute 
                top-0
                left-1/2
                transform
                -translate-y-full
                -translate-x-1/2;
            line-height: 0;
        }
    }

    &-r,
    &-right{
        &::before{
            @apply
                left-full
                top-1/2
                -translate-y-1/2
                rotate-90;
            --tw-translate-x: -10px
        }
    }

    &-b,
    &-bottom{
        &::before{
            @apply
                left-1/2
                top-full
                rotate-180
                translate-y-0;
        }
    }

    &-l,
    &-left{
        &::before{
            @apply
                left-0
                top-1/2
                -rotate-90
                origin-bottom;
                --tw-translate-y: -17px
        }
    }
}


.Card{
    @apply
        block
        bg-white
        rounded-lg
        shadow-sm
        overflow-hidden;

    &-image{
        @apply
            aspect-w-16 
            aspect-h-9;
        
        img{
            @apply 
                w-full 
                h-full 
                object-center 
                object-cover;
        }
    }

    &-body{
        @apply
            flex
            flex-col
            px-[25px]
            py-2
            space-y-1;
    }
}