.CSMeta{
    @apply 
        border-l
        border-gray
        pl-2
        flex-1
        font-light
        text-14;

    &:first-child{
        @apply border-l-0 p-0;
    }
        
    &-number{
        @apply flex gap-1;
    }

    @screen sm{
        @apply
            border-l-0
            border-b
            pl-0
            mb-2
            pb-2;
    }
}
.CaseStudyProjectBy {
    padding-bottom: 5px;
}
.CaseStudyLocation {
    padding-bottom: 5px;
    &-icon {
        height: 16px;
        display: inline-block;
        position: relative;
        top: -3px;
    }
    &-text {
        display: inline-block;
        padding-left: 5px;
        padding-top:2px;
    }
}
.CaseStudyGallery{
    @apply relative rounded-lg overflow-hidden;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);

    &-nav{
        @apply
            absolute 
            -right-1 
            top-1/2 
            transform 
            -translate-y-1/2 
            grid 
            grid-cols-1 
            grid-flow-row 
            gap-1;
    }

    &-nav .btn--prev::before{
        @apply rotate-0;
    }

    &-nav .btn--next::before{
        @apply rotate-180;
    }

    @screen md{
        &-nav{
            @apply 
                right-2
                top-0
                -translate-y-1/2
                grid-flow-col;
        }
        &-nav .btn--prev::before{
            @apply -rotate-90;
        }

        &-nav .btn--next::before{
            @apply rotate-90;
        }
    }

    &-thumb{
        @apply
            w-8 
            h-8 
            rounded-lg 
            shadow-lg 
            overflow-hidden
            cursor-pointer
            border-2
            border-transparent
            relative;

        &::before{
            content: '';
            @apply absolute inset-0 bg-blue-bright;
            z-index: -1;
        }

        img{
            @apply transition-all duration-500 ;
        }

        @screen md{
            @apply hidden;
        }
    }

    &-thumb.is-active{
        @apply
            border-blue-bright
            border-2;

        img{
            @apply
                transform
                scale-110
                opacity-50;
        }
    }
}

.CSTabSection{
    @apply relative my-8;

    &::before{
        content: '';
        @apply
            bg-blue
            absolute
            inset-x-0
            inset-y-10;
    }
}

.CSTabs{
    @apply z-10 relative;

    &-tabs{
        @apply flex;
    }

    &-tab{
        @apply
            uppercase
            text-12
            font-montserrat
            -mb-2
            bg-gray-400
            relative
            z-10
            shadow-lg
            rounded-lg
            rounded-b-none
            flex
            pr-3 
            -mr-3;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);

        a{
            @apply
                pt-2
                pb-4
                px-4;

        }

        &:last-child:not(.is-active){
            @apply z-0;
        }

        &.is-active{
            @apply
                text-red
                bg-white
                z-50
                pr-0
                mr-0;
            
            //hide the drop shadow
            &::after{
                content: '';
                @apply
                    absolute
                    -inset-x-1
                    -bottom-1
                    h-3
                    bg-white;
            }
            //first tab shadow hider doesn't cover left shadow
            &:first-child::after{
                @apply left-0;
            }
        }

        //make tabs overlap
        &.is-active + &,
        &.is-active + & + &{
            @apply pl-3 -ml-3 pr-0 mr-0;
        }

    }

    &-body{
        @apply 
            flex
            rounded-lg
            overflow-hidden
            shadow-lg
            bg-white
            relative
            z-40;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);

        @screen md+{
            @apply flex-col
        }

        @screen sm {
            @apply  rounded-none;
        }
    }

    &-copy{
        @apply flex-1 py-5;
    }

    &-description {
        max-height: 480px;
        overflow: auto;

        p {
            @apply  pr-2
                    pb-1;
        }

        @screen sm {
            @apply  max-h-[unset]
                    overflow-auto;
        }
    }

    &-visual{
        @apply flex-1;

        @screen sm {
            @apply  relative;
        }
    }

    &-nav{
        @apply 
            absolute 
            bottom-0
            transform
            translate-y-1/2
            left-2
            z-50;
    }
}

.CSTabsMobile {
    @apply  w-full
            bg-blue
            py-3
            text-white
            overflow-hidden
            flex
            justify-between
            relative;

    &-slider {
        @apply  relative
                w-full
                after:content-['flickity']
                after:hidden;
    }

    &-slide {
        @apply  w-full
                min-w-max
                text-center
                transition-all;
    }

    &-nav {
        @apply  absolute
                top-0
                left-2
                -translate-y-1/2
                z-50;
    }
}


.CSService{
    @apply
        flex-1 
        flex 
        flex-col 
        border-l 
        border-gray
        px-6
        pb-5;
    
    &:first-child{
        @apply border-none;
    }

    @screen lg{
        @apply
            px-3;
    }

    @screen md{
        @apply 
            border-t
            border-l-0
            pt-4;
    }
}