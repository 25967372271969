// Sprig loading state
#caseStudiesOverview{
    @apply opacity-100 flex;
}
#caseStudiesOverview.htmx-request{
    @apply opacity-50 cursor-wait;
}

.Hero {
    &--caseStudy {
        @screen md {
            background-image: var(--img);
                > .container {
                max-width: 480px;
                justify-content: flex-start;
    
                > h1 {
                    @apply  px-0;
                }
            }
        }
        @screen sm {
            background-position-x: -400px;
            h1 {
                    padding-left:1.875rem !important;
                    padding-right:1.875rem !important;
                }
        }
    }
}

.CaseStudySidebar{
    @apply 
        w-4/12  
        pr-4 
        pt-4 
        bg-white 
        min-h-screen;

    @screen lg{
        @apply w-5/12;
    }

    @screen md+{
        @apply 
            fixed 
            w-1/2
            pl-4
            left-0 
            top-0 
            z-50
            pt-3
            transform
            -translate-x-full
            transition-all
            shadow-sidebar;
        
        &.is-open{
            @apply translate-x-0;
            z-index: 999999999999;
        }
    }
}
.CaseStudyFilters{
    @apply flex flex-wrap gap-1;

    li{
        @apply flex-shrink-0;
    }

    &-tag{
        @apply
            text-12
            relative
            text-white
            bg-gray-500
            px-2
            py-sm
            font-medium
            rounded-full
            inline-block
            cursor-pointer;
        
        &.is-active{
            @apply bg-blue-bright;
            
        }

        &:not(.is-active):hover{
            @apply bg-gray-600;
        }
    }

    &-checkbox{
        @apply 
            opacity-0
            absolute;
    }
}

.FilterHeader{
    @apply
        flex 
        justify-between 
        mt-4 
        pb-1 
        mb-2 
        border-b 
        border-gray-400;

    &-reset{
        @apply 
            text-blue-bright 
            text-14 
            font-montserrat
            font-medium
            leading-8;
    }
}