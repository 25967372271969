.optin {
    .bg-white {
        text-align: center;
        display: block;
    }
    .shadow-top {
        text-align: center;
        display: block;
    }
    .iframe_class {
        display: inline-block;
        -webkit-box-shadow: 8px 8px 8px 5px rgba(227,223,227,1);
        -moz-box-shadow: 8px 8px 8px 5px rgba(227,223,227,1);
        box-shadow: 8px 8px 8px 5px rgba(227,223,227,1);
    }
}
@media screen and (max-width: 768px) {
    .optin {
        .iframe_class {
            iframe {
                min-width: 100% !important;
                min-height: 650px !important;
            }
        }
    }
}
