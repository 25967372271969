.ListingHero {
    &-header {
        &--news {
            background-position: top 55% right;
        }

        &--news {
            background-position: right 20% top 55%;
        }
        
        @screen xl+ {
            &--news {
                background-position: right 30% top 55% !important;
            }
        }

        @screen uw {
            &--news {
                background-position: right 35% top 55% !important;
            }
        }

        @screen uw+ {
            &--news {
                background-position: right 40% top 55% !important;
            }
        }
    }
}

.NewsListings {
    .ListingSearch {
        &-checkboxes {
            @apply  my-2;
    
            label {
                @apply  mt-[0.825rem]
                        mb-1
                        flex
                        items-start;
    
                &::last-child {
                    @apply  mb-0;
                }
            }
        }

        &-textInput {
            @apply  w-full
                    pl-4
                    ml-1;
        }
    }
}

.NewsResourcesOverview {
    .ListingHero-articles {
        grid-template-rows: 23.75rem;

        @screen lg {
            grid-template-rows: unset;
        }
    }
}

.PressIcon {
    align-self: flex-start;
    padding-top: 3px;
}