html{
    @apply text-base font-sans bg-white;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply font-sans text-base text-gray-800;
}

/*************************************
* Headings
*************************************/

h1, h2, h3,
h4, h5, h6 {
    @apply font-light font-montserrat;

    &+p{
        @apply mt-1;
    }
}

h1{ @apply text-54; }
h2{ @apply text-50; }
h3{ @apply text-45; }
h4{ @apply text-38;}
h5{ @apply text-34;}
h6{ @apply text-28;}

@screen md {
    h1 {
        @apply  text-28;
    }
}

@screen sm{
    h2{ }
    h3{ }
    h4{ }
    h5{ @apply text-28; }
    h6{ }
}


/*************************************
* Links
*************************************/

a{

    &:hover,
    &:focus{

    }
}

/*************************************
* Other Type
*************************************/


p {
    @apply  text-gray-800
            font-azo
            font-light
            tracking-normal
            leading-6;
}

p, dl, ol, ul, pre, blockquote {

}

.prose {
    ol {
        @apply list-decimal;
    }

    ul {
        @apply list-disc;
    }

    ol li,
    ul li {
        @apply pl-2;
    }
}
