//Helpers
@import "bourbon";
@import "lib/rem";
@import "lib/variables";
@import 'lib/mixins';

//Base
@import "tailwindcss/base";
@import 'lib/typography';

//Components
@import "tailwindcss/components";
@import "lib/components/plyr/_plyr";
@import 'lib/components/flickity';
@import "lib/components/box";
@import "lib/components/tab";
@import 'lib/components/buttons';
@import 'lib/components/forms';
@import 'lib/components/hero';
@import 'lib/components/statBox';
@import 'lib/components/CTA-blocks';
@import 'lib/components/newsletter';
@import 'lib/components/testimonials';
@import 'lib/components/footer';
@import 'lib/components/nav';
@import 'lib/components/social';
@import 'lib/components/caseStudyCarousel';
@import 'lib/components/shadow';



//Utilities
@import "tailwindcss/utilities";
@import "lib/customUtilities";

js-loading *,
.js-loading *:before,
.js-loading *:after {
  animation-play-state: paused !important;
}

[id] { scroll-margin-top: 2em; }

[x-cloak] { display: none; }

iframe{ max-width: 100%;}

html { scroll-behavior: smooth; }

body {
  @apply  relative;
  max-width: 100%;
  overflow-x: hidden;
}

main {
  // overflow-x: hidden;
}

html::before{
  content:'xl';
  @apply
    fixed
    top-1
    left-1
    z-50
    bg-red-bright
    text-white
    p-1
    uppercase
    text-14
    font-semibold;
    z-index: 99999;
    @screen lg+{ content: 'lg+' }
    @screen lg{ content: 'lg' }
    @screen md+{ content: 'md+' }
    @screen md{ content: 'md' }
    @screen sm{ content: 'sm' }
}
:root{
  --container: #{$xl};
    // border: 1px solid yellow;
    @screen xl{ --container: #{$lg-plus}; }
    @screen lg+{ --container: #{$lg}; }
    @screen lg{ --container: #{$md-plus}; }
    @screen md+{--container: #{$md}; }
    @screen md{ --container: #{$sm}; }
    @screen sm{ --container: 90%; }
}
.container{
    @apply max-w-xl;
    // border: 1px solid yellow;
    @screen xl{ @apply max-w-lg+; }
    @screen lg+{ @apply max-w-lg; }
    @screen lg{ @apply max-w-md+; }
    @screen md+{ @apply max-w-md; }
    @screen md{ @apply max-w-sm; }
    @screen sm{ 
      	max-width: 90%;

      	.full-width{
			margin-left: -5.556%;
			margin-right: -5.556%;
			width: auto;
			max-width: 111.1%;
			border-radius: 0;
      	} 
    }

    
}
.container-wide {
  max-width: 1820px;
}
.container-tight {
  max-width: 1100px;
}
.container-homeHero {
  @screen xl {
    max-width: 1440px;
  }
}

.osano-cm-toggle__label.osano-cm-label {
    color: #fff !important;
}
.osano-cm-view__description {
    color: #fff !important;
}
.osano-cm-list-item__description {
    color: #fff !important;
}
.osano-cm-list-item__description p {
    color: #fff !important;
}
.osano-cm-info-dialog-header__header {
    color:  #fff !important;
}


/*************************************
* Pages
*************************************/

@import "pages/home";
@import "pages/blogDetail";
@import "pages/blogOverview";
@import "pages/solutionsOverview";
@import "pages/solutionsEntry";
@import "pages/servicesEntry";
@import "pages/servicesOverview";
@import "pages/caseStudiesOverview";
@import "pages/caseStudyDetail";
@import "pages/marketsOverview";
@import "pages/newsDetail";
@import "pages/newsOverview";
@import "pages/aboutOverview";
@import "pages/partnersDetails";
@import "pages/resourcesOverview";
@import "pages/joinUs";
@import "pages/people";
@import "pages/history";
@import "pages/nrpOverview";
@import "pages/values";
@import "pages/partnersList";
@import "pages/contact";
@import "pages/careers";
@import "pages/sitemap";
@import "pages/myAEG";
@import "pages/myAEGDashboard";
@import "pages/team";
@import "pages/optin";
