.CaseStudyCarousel{
    @apply 
        mt-8 
        z-50
        after:content-['flickity']
        after:hidden;
    height: 240px;

        
    

    &-cell{
        @apply px-2 transition-all rounded-b-lg overflow-hidden;
        width: calc( 1400px / 3 );
        min-height: 100%;

        .Card-image img,
        .Card-image{
            height: 240px;
        }

        .Card{
            @apply h-full;
        }

        @screen xl{
            width: calc( 1024px / 3 );
        }
        @screen lg{
            width: calc( 980px / 3 );

            p{
                @apply line-clamp-3;
            }
        }

        @screen md{
            width: calc( 480px / 2 );
            @apply grid px-1;
        }

        @screen sm{
            width: 75%;
        }
    }

    //fade out hidden cards
    [aria-hidden]{
        @apply opacity-70;
    }

    .flickity-page-dots{
        @apply relative;
    }
}