.NewsletterForm {
    .fui-form-container {
        .fui-page {
            @apply  rounded-full 
                    flex 
                    items-center
                    justify-between 
                    p-1 
                    shadow-innerThick
                    bg-white;

            .fui-field:not(.fui-type-hidden-field) {
                @apply  mb-0;
            }

            input[type=email] {
                @apply  rounded-full 
                        w-full
                        pl-1 
                        bg-transparent
                        text-gray-900
                        border-0
                        mt-0
                        h-full
                        placeholder:font-azo
                        placeholder:font-light;

                &:focus {
                    @apply  outline-none;
                }
                
                @screen md {
                    @apply  w-full;
                }
            }

            .fui-btn-container {
                @apply  pt-0
                        mt-0;

                .fui-submit {
                    @extend  .btn;
                    @apply  border-0;
                }
            }

            @screen md {
                .fui-page-container {
                    @apply  order-2;
                }

                .fui-btn-container {
                    @apply  order-1;
                }
            }
        }
    }
}