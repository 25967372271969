.MyAEGCta {
    @apply  min-h-[500px]
            mt-10;

        
    background-image: url($dist + '/AGraduated-color@2x.png');
    background-repeat: no-repeat;
    background-size: 654px auto;

    &-container {
        @apply  flex 
                items-start 
                justify-center;

        @screen md+ {
            @apply  flex-col
                    max-w-[80%]
                    mx-auto;
        }

        @screen sm+ {
            @apply  max-w-[90%];
        }
    }

    &-title {
        @apply  text-red
                bg-white
                font-montserrat
                text-45
                font-light
                tracking-normal
                leading-[55px]
                max-w-[350px]
                py-4
                mr-8
                px-[37px]
                shadow
                rounded-lg;

        @screen md+ {
            @apply  mx-auto
                    text-28
                    leading-[34px]
                    p-2
                    shadow-aboutGrid;
        }

    }


    &-formContainer {
        @apply  bg-blue
                rounded-lg
                text-white
                pt-[78px]
                pb-[58px]
                flex
                w-full
                max-w-[770px]
                items-start
                justify-center;

        &--bgNone {
            background-color: transparent !important;
        }

        @screen md+ {
            @apply  max-w-[90%]
                    mx-auto
                    rounded-lg
                    px-6
                    py-4
                    pt-9;
        }

        @screen  sm+ {
            @apply  overflow-hidden
                    px-3
                    pt-12;
        }

        @screen  sm {
            @apply  pt-15;
        }
    }

    &-form {
        @apply  w-9/12
                block;

        @screen md+ {
            @apply  w-10/12
                    mx-auto;
        }

        @screen md {
            @apply  w-full;
        }

        @screen sm+ {
            @apply  overflow-hidden;
        }
    }

    &-field {
        @apply  mb-1
                flex;

        @screen md+ {
            @apply  w-full
                    justify-between;
        }

        &:last-child {
            @apply  mb-0;
        }

        &--interest {
            @screen md+ {
                @apply  flex-col
                        items-start;
            }
        }

        label, > p {
            @apply  font-montserrat
                    text-38
                    font-light
                    tracking-[-0.3px]
                    leading-[50px]
                    pr-1
                    min-w-max;

            @screen  md+ {
                @apply  text-21
                        leading-[25px];
            }
        }

        > p {
            @apply  self-end
                    ml-[6px];

            @screen md+ {
                @apply  hidden
                        absolute
                        -right-full;
            }
        }

        input[type=text], input[type=text]:valid {
            @apply  border-b
                    border-white
                    border-dashed
                    text-white
                    font-montserrat
                    text-38
                    font-light
                    tracking-[-0.3px]
                    leading-[50px]
                    w-full
                    min-w-[100px]
                    text-left;
            background: transparent !important;
            background-color: transparent !important;

            @screen md+ {
                @apply  text-21
                        tracking-normal
                        leading-[25px]
                        w-full;
            }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active{
            transition: background-color 5000s;
            -webkit-text-fill-color: #fff !important;
            -webkit-box-shadow: none !important;
        }

        select {
            @apply  appearance-none
                    w-full;
        }

        &:nth-child(odd) {
            label, > p {
                @apply  text-[#FF2F2F];
            }
        }

        &:nth-child(even) {
            label, > p {
                @apply  text-[#00A3E0];
            }
        }

        &--select2 {

            /* @apply  w-full
                    min-w-min; */
            /* @screen md {
                @apply  w-full;
            }

            select.id-10t-fix {
                // @apply  w-full;
                &--iama, &--location {
                    min-width: 407px;
                }

                &--interest {
                    min-width: 281px;
                }

                @screen md {
                    &--iama, &--interest, &--location {
                        min-width: min-content;
                        @apply  w-full;
                    }
                }
            }
            */
            .select2 {
                &-container {
                    &--default {
                        .select2-selection {
                            &--single {
                                @apply  bg-transparent
                                        border-0
                                        border-b
                                        border-white
                                        border-dashed
                                        h-auto
                                        w-auto;

                                @screen md+ {
                                    @apply  min-h-[34px];
                                }
                                .select2-selection {
                                    &__rendered {
                                        @apply  text-white
                                                font-montserrat
                                                text-38
                                                font-light
                                                tracking-[-0.3px]
                                                h-full
                                                min-h-[50px]
                                                text-right
                                                leading-[50px]
                                                pr-3;

                                        @screen md+ {
                                            @apply  text-21
                                                    leading-[25px]
                                                    tracking-normal
                                                    min-h-[34px]
                                                    pr-0;
                                        }
                                    }    

                                    &__arrow {
                                        @apply  h-[9px]
                                                w-[16px]
                                                top-1/2
                                                right-0;

                                        b {
                                            @apply  border-none
                                                    border-transparent
                                                    border-0
                                                    h-[9px]
                                                    w-[16px]
                                                    ml-0
                                                    mt-0
                                                    top-0
                                                    left-0
                                                    origin-center
                                                    transition-all
                                                    rotate-180;

                                            content: url($dist + '/down-arrow--select.svg');
                                        }
                                    }
                                }
                            }

                        }

                    }

                    &--open {
                        .select2 {
                            &-dropdown {
                                @extend .box;
                                @include caret('top');                    
                            }

                            &-selection {
                                &__arrow {
                                    b {
                                        @apply  rotate-0;
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
    }


    &.MyAEGCta-floatTitle {
        .MyAEGCta {

            &-title {
                @apply  -mr-5
                        -mt-[66px]
                        z-40;
            }

            &-formContainer {
                @apply  z-30;
            }


            @screen md+ {

                &-container {
                    @apply  relative;
                }

                &-title {
                    @apply  absolute
                            top-0
                            left-1/2
                            -translate-x-1/2;
                }


                &-formContainer {
                    @apply  pt-9;
                }
            }
        }
    }

    &.MyAEGCta-blueBg {
        background-image: none;

        .MyAEGCta {
            &-formContainer {
                @apply  bg-transparent;
            }

            &-field {
                @apply  mb-2;
            }
        }
    }

}

.Footer.Footer-myAEGcta {
    background: $blue url($dist + "/A-graduated-1.png") 100% 100% no-repeat;   
}


.select2 {
    &-container {

        // @apply  mt-3;

        &--default {
            .select2-selection {
                &--single {
                    @apply  bg-transparent
                            border-0
                            border-b
                            border-white
                            border-dashed
                            rounded-none
                            leading-[50px]
                            min-h-[50px]
                            w-full;

                    .select2-selection__rendered {
                        @apply  text-white
                                font-montserrat
                                text-38
                                font-light
                                tracking-[-0.3px]
                                h-full;
                    }
                }
            }

        }
        
        &--open {
            .select2 {
                &-dropdown {

                    @extend .box;
                    @include caret('top');      

                    @apply  translate-y-3;
                    
                    &--below {
                        @apply  rounded-lg
                                p-1;
                    }
    
                    &::before {
                        @apply  left-[unset]
                                right-0;
                    }
                    
                    @apply  pt-2;
                }

                &-search {
                    @apply  hidden;
                }
            }
        }
    }

    &-results {
        &__option {
            &--selectable {

                color: rgba(0,48,82,0.6);

                @apply  font-azo
                        text-[15px]
                        tracking-normal
                        leading-[19px];

                &:first-letter {
                    @apply  capitalize;
                }
            }
        }
    }
}